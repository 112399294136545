import {
  type ShoppingStore,
  type ServingAddressDetailsResponse,
} from '@/services/ShoppingStore/types'
import { apiGet } from '@/utils/dataFetching'
import { API_URL } from '@/utils/dataFetching/apiUrl'
import { normalizeStoresResponse } from '@/services/ShoppingStore/utils'
import { type QueryFunctionContext } from '@tanstack/react-query'
import {
  type ShoppingStoresKey,
  type ShoppingStoreByIdQueryKey,
  type getHomepageStoresQueryKey,
} from '@/services/ShoppingStore/queries'

export const fetchShoppingStores = async (
  context: QueryFunctionContext<ShoppingStoresKey>
) => {
  const [, params] = context.queryKey
  const response = await apiGet<ServingAddressDetailsResponse>({
    config: {
      url: 'api/v1/store_locations/serving_address_with_details',
      params,
    },
    options: {
      apiUrl: API_URL.MEMBER_API,
    },
    context,
    fetcherName: 'fetchShoppingStores',
  })
  return normalizeStoresResponse(response)
}

export const fetchShoppingStoreById = (
  context: QueryFunctionContext<ShoppingStoreByIdQueryKey>
) => {
  const [, { storeId }] = context.queryKey
  return apiGet<ShoppingStore>({
    config: { url: `api/v1/stores/${storeId}.json` },
    options: { apiUrl: API_URL.MEMBER_API },
    context,
    fetcherName: 'fetchShoppingStoreById',
  })
}

export const fetchHomepageStores = async (
  context: QueryFunctionContext<ReturnType<typeof getHomepageStoresQueryKey>>
) => {
  const [, params] = context.queryKey
  const response = await apiGet<ServingAddressDetailsResponse>({
    config: {
      url: 'api/v1/store_locations/serving_address_with_details/homepage',
      params,
    },
    options: {
      apiUrl: API_URL.MEMBER_API,
    },
    context,
    fetcherName: 'fetchHomepageStores',
  })
  return normalizeStoresResponse(response)
}
