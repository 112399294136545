// disable routing rules cause this is the routes file!
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable shipt/no-hardcoded-routes */
import { authRoutes } from '@/serverUtils/auth/constants'
import { type NextRouter } from 'next/router'
import { pageRoutes } from '@shared/constants/pathnames'

export type ShiptRoute = {
  url: string
  pageTitle?: string
  delayMedallia?: true
  authentication: boolean
  hidePartnerName?: true
  crew:
    | 'purchase'
    | 'acquisition'
    | 'platform'
    | 'memberships'
    | 'tofu'
    | 'connections'
    | 'shop_and_browse'
  /** Enable for pages that guest users should not access */
  noGuestAccess?: true
  /** Enable for pages that require a store context */
  storeRequired?: true
  noIndex?: true
} & (
  | { dynamicPath: true; trackingName?: never }
  | { dynamicPath?: never; trackingName: string }
)

export enum RouteName {
  ACCESSIBILITY = 'ACCESSIBILITY',
  ACTIVATE_TARGET_ACCOUNT = 'ACTIVATE_TARGET_ACCOUNT',
  ADDRESS = 'ADDRESS',
  ADVANCED_SECURITY = 'ADVANCED_SECURITY',
  AUTH0_ERROR = 'AUTH0_ERROR',
  AMBASSADOR = 'AMBASSADOR',
  BLOG = 'BLOG',
  BLOG_POST = 'BLOG_POST',
  BRANCH = 'BRANCH',
  BRANCH_UNGATED = 'BRANCH_UNGATED',
  BUY_AGAIN = 'BUY_AGAIN',
  CANCELLATION = 'CANCELLATION',
  CART = 'CART',
  CART_CONVERSION_LOADING = 'CART_CONVERSION_LOADING',
  CITIES = 'CITIES',
  CITIES_INDEX = 'CITIES_INDEX',
  CHECK_EMAIL = 'CHECK_EMAIL',
  CHECKOUT = 'CHECKOUT',
  CHOOSE_MEMBERSHIP = 'CHOOSE_MEMBERSHIP',
  CMS_MODULE = 'CMS_MODULE',
  CMS_PREVIEW_PAGE = 'CMS_PREVIEW_PAGE',
  CONTACT = 'CONTACT',
  CORPORATE = 'CORPORATE',
  COMMUNITY = 'COMMUNITY',
  COUPON = 'COUPON',
  COUPONS = 'COUPONS',
  DATA_PRIVACY = 'DATA_PRIVACY',
  DEALS = 'DEALS',
  DELIVERY = 'DELIVERY',
  DELIVERY_OPTIONS = 'DELIVERY_OPTIONS',
  DIETARY_PREFERENCES = 'DIETARY_PREFERENCES',
  DISCOVER = 'DISCOVER',
  DRIVER = 'DRIVER',
  DRIVER_INTAKE_FORM = 'DRIVER_INTAKE_FORM',
  EMAIL_VERIFICATION_REQUIRED = 'EMAIL_VERIFICATION_REQUIRED',
  FEATURED_PROMOTIONS = 'FEATURED_PROMOTIONS',
  GIFT = 'GIFT',
  GLOBAL_HOMEPAGE = 'GLOBAL_HOMEPAGE',
  GLOBAL_LANDING_PAGE = 'GLOBAL_LANDING_PAGE',
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
  GATED_CATEGORY = 'GATED_CATEGORY',
  GUEST_WELCOME = 'GUEST_WELCOME',
  HI = 'HI',
  RETAILER_HOMEPAGE = 'RETAILER_HOMEPAGE',
  LADDERUP = 'LADDERUP',
  LANDING_PAGE = 'LANDING_PAGE',
  LANDING_PAGE_FOR_YOU = 'LANDING_PAGE_FOR_YOU',
  LISTS_HUB = 'LISTS_HUB',
  LIST = 'LIST',
  LP = 'LP',
  LEGACY_WELCOME = 'LEGACY_WELCOME',
  LEGACY_ZIP_FORM = 'LEGACY_ZIP_FORM',
  LEGAL = 'LEGAL',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  MEMBERSHIP_DASHBOARD = 'MEMBERSHIP_DASHBOARD',
  MULTI_CART = 'MULTI_CART',
  MULTI_CHECKOUT = 'MULTI_CHECKOUT',
  OFFER = 'OFFER',
  ORDER_CONFIRMATION = 'ORDER_CONFIRMATION',
  ORDER_DETAIL = 'ORDER_DETAIL',
  ORDER_LIST = 'ORDER_LIST',
  PARTNER = 'PARTNER',
  PASSWORD_RESET = 'PASSWORD_RESET',
  PREFERRED = 'PREFERRED',
  PRICING = 'PRICING',
  PRODUCT = 'PRODUCT',
  PROFILE = 'PROFILE',
  PRIVACY_RIGHTS_REQUESTS = 'PRIVACY_RIGHTS_REQUESTS',
  REDEEM = 'REDEEM',
  REDEEM_SIGNUP = 'REDEEM_SIGNUP',
  REDEEM_PAYMENT = 'REDEEM_PAYMENT',
  REDEEM_LOGIN = 'REDEEM_LOGIN',
  RETAILERS_LOADING = 'RETAILERS_LOADING',
  REWARD = 'REWARD',
  SEARCH = 'SEARCH',
  SECURE_ADD_PAYMENT = 'SECURE_ADD_PAYMENT',
  SHIPTAI = 'SHIPTAI',
  SHOPPER = 'SHOPPER',
  SHOPPER_ALREADY_APPLIED = 'SHOPPER_ALREADY_APPLIED',
  SIGN_UP = 'SIGN_UP',
  SITEMAP_INDEX = 'SITEMAP_INDEX',
  SPECIAL_REQUESTS = 'SPECIAL_REQUESTS',
  STORES = 'STORES',
  STUDENT = 'STUDENT',
  SUBSTITUTION_PREFERENCES_ORDER = 'SUBSTITUTION_PREFERENCES_ORDER',
  SHIPT_ACCOUNT_ACTIVATION = 'SHIPT_ACCOUNT_ACTIVATION',
  TARGET_CIRCLE_360 = 'TARGET_CIRCLE_360',
  UNGATED_CATEGORY = 'UNGATED_CATEGORY',
  UNGATED_CATEGORY_HUB = 'UNGATED_CATEGORY_HUB',
  UNGATED_HOMEPAGE = 'UNGATED_HOMEPAGE',
  UNGATED_PROMOTIONS_PAGE = 'UNGATED_PROMOTIONS_PAGE',
  UNIVERSAL_PRODUCT = 'UNIVERSAL_PRODUCT',
  USBANK = 'USBANK',
  VISA = 'VISA',
  WALLET = 'WALLET',
  WELCOME = 'WELCOME',
}

type NonCmsRouteDictionary = {
  [P in Exclude<RouteName, CmsRouteName>]: ShiptRoute
}
const nonCmsRouteDictionary = {
  ACCESSIBILITY: {
    url: '/shop/account/accessibility',
    trackingName: 'accessibility',
    authentication: true,
    noGuestAccess: true,
    pageTitle: 'Accessibility',
    crew: 'memberships',
  },
  ACTIVATE_TARGET_ACCOUNT: {
    url: '/activate-target-account',
    trackingName: 'activate_target_account',
    pageTitle: 'Activate Target Account',
    authentication: false,
    crew: 'memberships',
  },
  ADDRESS: {
    url: '/shop/account/addresses',
    trackingName: 'addresses',
    authentication: true,
    noGuestAccess: true,
    pageTitle: 'Addresses',
    crew: 'memberships',
  },
  ADVANCED_SECURITY: {
    url: '/shop/account/advanced-security',
    trackingName: 'advanced_security',
    authentication: true,
    noGuestAccess: true,
    crew: 'acquisition',
  },
  AUTH0_ERROR: {
    url: '/auth/error',
    authentication: false,
    trackingName: 'auth_error',
    crew: 'platform',
  },
  BLOG: {
    url: '/blog',
    trackingName: 'blog',
    authentication: false,
    pageTitle: 'Blog - Recipes & More from Shipt | Same-Day Delivery',
    crew: 'tofu',
  },
  BLOG_POST: {
    url: '/blog',
    authentication: false,
    trackingName: 'blog_post',
    crew: 'tofu',
  },
  BRANCH: {
    url: '/shop/branch',
    trackingName: 'branch_landing',
    authentication: true,
    noGuestAccess: true,
    crew: 'platform',
  },
  BRANCH_UNGATED: {
    url: '/shop/branch/u',
    trackingName: 'branch_landing',
    authentication: false,
    crew: 'platform',
  },
  BUY_AGAIN: {
    url: '/shop/buy_again',
    trackingName: 'buy_again',
    authentication: true,
    noGuestAccess: true,
    pageTitle: 'Buy Again',
    crew: 'shop_and_browse',
  },
  CANCELLATION: {
    url: '/shop/membership-dashboard/cancellation',
    trackingName: 'cancellation',
    authentication: true,
    noGuestAccess: true,
    crew: 'memberships',
  },
  CART: {
    url: '/shop/cart',
    trackingName: 'cart',
    authentication: true,
    storeRequired: true,
    crew: 'purchase',
  },
  CART_CONVERSION_LOADING: {
    url: '/shop/cart/loading',
    trackingName: 'cart_conversion_loading',
    authentication: true,
    crew: 'purchase',
  },
  CHECK_EMAIL: {
    url: '/target-circle-360/check-email',
    trackingName: 'check_email',
    authentication: false,
    crew: 'acquisition',
    pageTitle: 'Target Circle 360 Activation',
  },
  CHECKOUT: {
    url: '/shop/checkout',
    trackingName: 'checkout',
    authentication: true,
    noGuestAccess: true,
    storeRequired: true,
    crew: 'purchase',
  },
  CHOOSE_MEMBERSHIP: {
    url: '/shop/choose-membership',
    trackingName: 'choose_membership',
    authentication: true,
    pageTitle: 'Choose Membership',
    crew: 'memberships',
  },
  CITIES_INDEX: {
    url: '/cities',
    authentication: false,
    pageTitle: 'Shipt Delivery in Your City',
    hidePartnerName: true,
    trackingName: 'cities',
    crew: 'tofu',
  },
  CMS_MODULE: {
    url: '/cms/module/',
    authentication: false,
    trackingName: 'cms',
    crew: 'tofu',
  },
  CMS_PREVIEW_PAGE: {
    url: '/cms/page',
    authentication: false,
    trackingName: 'cms',
    crew: 'tofu',
  },
  CONTACT: {
    url: '/contact',
    authentication: false,
    hidePartnerName: true,
    pageTitle: "Let's Chat! - Store to Door Delivery - Contact Shipt",
    trackingName: 'contact',
    crew: 'tofu',
  },
  COUPON: {
    url: '/shop/coupon',
    trackingName: 'coupon',
    authentication: true,
    crew: 'shop_and_browse',
  },
  COUPONS: {
    url: '/shop/coupons',
    trackingName: 'coupons',
    authentication: true,
    crew: 'shop_and_browse',
  },
  DATA_PRIVACY: {
    url: '/shop/account/data-privacy',
    trackingName: 'data_privacy',
    authentication: true,
    noGuestAccess: true,
    pageTitle: 'Data Privacy',
    crew: 'memberships',
  },
  DEALS: {
    url: '/shop/deals',
    trackingName: 'savings',
    authentication: true,
    storeRequired: true,
    crew: 'tofu',
  },
  DELIVERY_OPTIONS: {
    url: '/shop/delivery-options',
    trackingName: 'delivery_options',
    authentication: true,
    noGuestAccess: true,
    storeRequired: true,
    crew: 'memberships',
  },
  DIETARY_PREFERENCES: {
    url: '/shop/account/dietary-preferences',
    trackingName: 'dietary_preferences',
    authentication: true,
    noGuestAccess: true,
    crew: 'connections',
  },
  DRIVER_INTAKE_FORM: {
    url: 'privacy/driver-intake-form',
    authentication: false,
    hidePartnerName: true,
    dynamicPath: true,
    crew: 'connections',
  },
  EMAIL_VERIFICATION_REQUIRED: {
    url: '/email-verification-required',
    trackingName: 'email_verification_required',
    authentication: false,
    pageTitle: 'Email Verification Required',
    crew: 'memberships',
  },
  FEATURED_PROMOTIONS: {
    url: '/shop/featured-promotions',
    trackingName: 'cpg_promotions',
    pageTitle: 'Featured Promotion',
    authentication: true,
    storeRequired: true,
    crew: 'shop_and_browse',
  },
  GATED_CATEGORY: {
    url: '/shop/categories',
    trackingName: 'category_page',
    authentication: true,
    crew: 'shop_and_browse',
  },
  GLOBAL_HOMEPAGE: {
    url: '/shop/stores',
    authentication: true,
    trackingName: 'global_home',
    crew: 'shop_and_browse',
  },
  GLOBAL_LANDING_PAGE: {
    authentication: true,
    crew: 'shop_and_browse',
    url: '/shop/stores/landing-page',
    trackingName: 'global-landing-page',
  },
  GLOBAL_SEARCH: {
    url: '/shop/stores/search',
    authentication: true,
    trackingName: 'global_search',
    crew: 'shop_and_browse',
  },
  GUEST_WELCOME: {
    url: '/shop/guest-welcome',
    trackingName: 'guest_welcome',
    authentication: false,
    crew: 'acquisition',
  },
  RETAILER_HOMEPAGE: {
    url: '/shop',
    trackingName: 'home',
    authentication: true,
    pageTitle: 'Home',
    storeRequired: true,
    crew: 'shop_and_browse',
  },
  LADDERUP: {
    url: '/ladderup',
    trackingName: 'ladderup',
    authentication: false,
    pageTitle: 'LadderUp - Shipt Small Business Ecommerce Accelerator',
    crew: 'tofu',
  },
  LANDING_PAGE: {
    url: '/shop/landing-page',
    trackingName: 'landing-page',
    authentication: true,
    storeRequired: true,
    crew: 'tofu',
  },
  LANDING_PAGE_FOR_YOU: {
    url: '/shop/landing-page/for-you',
    trackingName: 'landing-page-for-you',
    authentication: true,
    crew: 'tofu',
  },
  LISTS_HUB: {
    url: '/shop/lists',
    trackingName: 'shopping_lists_hub',
    authentication: true,
    storeRequired: true,
    crew: 'shop_and_browse',
  },
  LIST: {
    url: '/shop/lists',
    trackingName: 'shopping_list',
    authentication: true,
    storeRequired: true,
    crew: 'shop_and_browse',
  },
  LEGACY_WELCOME: {
    url: '/shop/welcome',
    trackingName: 'welcome',
    authentication: true,
    crew: 'acquisition',
  },
  LEGACY_ZIP_FORM: {
    url: '/shop/zip',
    trackingName: 'zip_form',
    authentication: false,
    crew: 'memberships',
  },
  LOGIN: {
    url: '/login',
    trackingName: 'login',
    authentication: false,
    crew: 'tofu',
  },
  LOGOUT: {
    url: '/logout',
    trackingName: 'logout',
    authentication: false,
    crew: 'tofu',
  },
  MEMBERSHIP_DASHBOARD: {
    url: '/shop/membership-dashboard',
    trackingName: 'member_dashboard',
    authentication: true,
    noGuestAccess: true,
    pageTitle: 'Membership Dashboard',
    crew: 'memberships',
  },
  MULTI_CART: {
    url: '/shop/multi-cart',
    trackingName: 'multi-cart',
    authentication: true,
    noGuestAccess: true,
    storeRequired: true,
    crew: 'purchase',
  },
  MULTI_CHECKOUT: {
    url: '/shop/multi-checkout',
    trackingName: 'multi-checkout',
    authentication: true,
    noGuestAccess: true,
    storeRequired: true,
    crew: 'purchase',
  },
  ORDER_CONFIRMATION: {
    url: '/shop/order-confirmation',
    trackingName: 'order-confirmation',
    delayMedallia: true,
    authentication: true,
    noGuestAccess: true,
    crew: 'shop_and_browse',
  },
  ORDER_DETAIL: {
    trackingName: 'order_detail',
    url: '/shop/account/orders',
    delayMedallia: true,
    authentication: true,
    noGuestAccess: true,
    pageTitle: 'Orders',
    crew: 'connections',
  },
  ORDER_LIST: {
    url: '/shop/account/orders',
    trackingName: 'order_list',
    authentication: true,
    pageTitle: 'Orders',
    crew: 'connections',
  },
  PASSWORD_RESET: {
    url: '/password-reset',
    trackingName: 'password_reset',
    pageTitle: 'Password Reset',
    authentication: false,
    crew: 'acquisition',
  },
  PREFERRED: {
    url: '/shop/account/preferred',
    trackingName: 'preferred',
    authentication: true,
    noGuestAccess: true,
    crew: 'connections',
  },
  PRIVACY_RIGHTS_REQUESTS: {
    url: '/privacy/intake-form',
    trackingName: 'privacy_rights_requests',
    authentication: false,
    pageTitle: 'Privacy Rights Requests',
    crew: 'tofu',
  },
  PRODUCT: {
    url: '/shop/products',
    trackingName: 'detail',
    authentication: true,
    storeRequired: true,
    crew: 'shop_and_browse',
  },
  PROFILE: {
    url: '/shop/account/profile',
    trackingName: 'profile',
    authentication: true,
    noGuestAccess: true,
    pageTitle: 'Profile',
    crew: 'memberships',
  },
  REDEEM_PAYMENT: {
    trackingName: 'redeem',
    url: '/shop/redeem/payment',
    authentication: true,
    crew: 'acquisition',
  },
  REDEEM_LOGIN: {
    url: '/shop/redeem/login',
    trackingName: 'redeem_login',
    authentication: false,
    crew: 'acquisition',
    noIndex: true,
  },
  REDEEM_SIGNUP: {
    url: '/shop/redeem',
    trackingName: 'redeem',
    authentication: false,
    crew: 'acquisition',
    noIndex: true,
  },
  RETAILERS_LOADING: {
    url: '/shop/retailers-loading',
    trackingName: 'retailers_loading',
    authentication: true,
    crew: 'acquisition',
  },
  REWARD: {
    url: '/shop/account/rewards',
    trackingName: 'rewards',
    authentication: true,
    noGuestAccess: true,
    pageTitle: 'Rewards',
    crew: 'acquisition',
  },
  SEARCH: {
    url: '/shop/search',
    trackingName: 'search',
    authentication: true,
    storeRequired: true,
    crew: 'shop_and_browse',
  },
  // PCI Experiment. Do not modify
  SECURE_ADD_PAYMENT: {
    url: '/secure/add-payment',
    trackingName: 'pci',
    authentication: true,
    crew: 'platform',
  },
  SHIPTAI: {
    url: '/shop/shiptai',
    trackingName: 'shiptai',
    authentication: true,
    storeRequired: true,
    noGuestAccess: true,
    crew: 'shop_and_browse',
  },
  SHOPPER_ALREADY_APPLIED: {
    url: '/shopper-already-applied',
    pageTitle: 'Duplicate Shopper Application',
    trackingName: 'shopper-already-applied',
    authentication: false,
    crew: 'tofu',
  },
  SIGN_UP: {
    url: '/signup',
    trackingName: 'sign_up',
    authentication: false,
    crew: 'acquisition',
  },
  SITEMAP_INDEX: {
    url: '/sitemap-index',
    pageTitle: 'Sitemap',
    trackingName: 'sitemap_index',
    authentication: false,
    crew: 'platform',
  },
  SPECIAL_REQUESTS: {
    url: '/shop/special-requests',
    trackingName: 'special_requests',
    authentication: true,
    noGuestAccess: true,
    crew: 'purchase',
  },
  SUBSTITUTION_PREFERENCES_ORDER: {
    url: '/shop/account/orders/substitution-preferences',
    trackingName: 'substitution_preferences_order',
    authentication: true,
    crew: 'connections',
  },
  SHIPT_ACCOUNT_ACTIVATION: {
    url: '/shipt-account-activation',
    trackingName: 'shipt_account_activation',
    pageTitle: 'Activate Your Account',
    noIndex: true,
    authentication: false,
    crew: 'memberships',
  },
  TARGET_CIRCLE_360: {
    url: '/target-circle-360',
    trackingName: 'target_circle_360',
    authentication: false,
    crew: 'acquisition',
  },
  UNGATED_CATEGORY: {
    url: '/categories',
    trackingName: 'ungated_category_page',
    authentication: false,
    crew: 'acquisition',
  },
  UNGATED_CATEGORY_HUB: {
    url: '/categories',
    trackingName: 'ungated_category_hub',
    authentication: false,
    crew: 'acquisition',
  },
  UNGATED_HOMEPAGE: {
    crew: 'acquisition',
    url: '/',
    trackingName: 'homepage_redesign',
    authentication: false,
    noGuestAccess: true,
    pageTitle: 'Your Local Stores Delivered - Shipt Same-Day Delivery',
  },
  UNGATED_PROMOTIONS_PAGE: {
    url: '/promotions',
    trackingName: 'ungated_promotions_page',
    authentication: false,
    crew: 'acquisition',
  },
  UNIVERSAL_PRODUCT: {
    url: '/up',
    trackingName: 'ungated_product_details',
    authentication: false,
    crew: 'acquisition',
  },
  VISA: {
    url: '/visa',
    authentication: false,
    trackingName: 'visa',
    pageTitle: 'Get a Free Shipt Trial from Visa',
    hidePartnerName: true,
    crew: 'tofu',
  },
  WALLET: {
    url: '/shop/account/wallet',
    authentication: true,
    trackingName: 'wallet',
    noGuestAccess: true,
    pageTitle: 'Wallet',
    crew: 'purchase',
  },
  WELCOME: {
    url: '/welcome',
    trackingName: 'welcome',
    authentication: false,
    crew: 'acquisition',
  },
} as const satisfies NonCmsRouteDictionary

const cmsRouteNames = [
  RouteName.AMBASSADOR,
  RouteName.CITIES,
  RouteName.COMMUNITY,
  RouteName.CORPORATE,
  RouteName.DELIVERY,
  RouteName.DISCOVER,
  RouteName.DRIVER,
  RouteName.GIFT,
  RouteName.HI,
  RouteName.LEGAL,
  RouteName.LP,
  RouteName.OFFER,
  RouteName.PARTNER,
  RouteName.PRICING,
  RouteName.REDEEM,
  RouteName.SHOPPER,
  RouteName.STORES,
  RouteName.STUDENT,
  RouteName.USBANK,
] as const

export type CmsRouteName = (typeof cmsRouteNames)[number]

type CmsRouteDictionary = {
  [P in CmsRouteName]: ShiptRoute
}
export const cmsRouteDictionary = cmsRouteNames.reduce<CmsRouteDictionary>(
  (acc, routeName) => ({
    ...acc,
    [routeName]: {
      authentication: false,
      crew: 'acquisition',
      dynamicPath: true,
      url: `/${routeName.toLowerCase()}`,
    } satisfies ShiptRoute,
  }),
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- cast as CmsRouteDictionary
  {} as CmsRouteDictionary
)

type RouteDictionary = {
  [P in keyof typeof RouteName]: ShiptRoute
}
export const routes = {
  ...cmsRouteDictionary,
  ...nonCmsRouteDictionary,
} as const satisfies RouteDictionary

// These are dynamic CMS route urls that we want to be able to reference within Segway
export const cmsRoutes = {
  SHOPPER: {
    BE_A_SHOPPER: '/shopper/be-a-shopper',
    SAFETY: '/shopper/shopper-safety',
    PERKS: '/shopper/shopper-perks',
    FAQ: '/shopper/shopper-faq',
    LIFE: '/shopper/shopper-life',
  },
  DRIVER: {
    BE_A_DRIVER: '/driver/be-a-driver',
  },
}

export function getRouteData(routeName: RouteName) {
  const routeData = routes[routeName] ?? {}
  return { ...routeData, routeName }
}

export const marketingPaths = [
  ...cmsRouteNames,
  RouteName.BLOG,
  RouteName.BLOG_POST,
  RouteName.CITIES_INDEX,
  RouteName.CONTACT,
  RouteName.LADDERUP,
  RouteName.PRIVACY_RIGHTS_REQUESTS,
  RouteName.SHOPPER_ALREADY_APPLIED,
  RouteName.TARGET_CIRCLE_360,
  RouteName.UNGATED_CATEGORY,
  RouteName.UNGATED_CATEGORY_HUB,
  RouteName.UNGATED_HOMEPAGE,
  RouteName.UNGATED_PROMOTIONS_PAGE,
  RouteName.UNIVERSAL_PRODUCT,
  RouteName.VISA,
]

export const globalHeaderPaths = [
  RouteName.GLOBAL_HOMEPAGE,
  RouteName.GLOBAL_SEARCH,
  RouteName.CANCELLATION,
  RouteName.MEMBERSHIP_DASHBOARD,
  RouteName.GLOBAL_LANDING_PAGE,
]

export function isAccountRoute(router: NextRouter) {
  return router.pathname.includes('/shop/account')
}

export enum AccountPageReferrers {
  GLOBAL_HOME_PAGE = 'home',
}

export const isValidRoute = (pathname: string) => {
  if (pathname === routes.RETAILER_HOMEPAGE.url) {
    return true
  }
  return (
    Object.keys(pageRoutes).some((route) => pathname.startsWith(route)) ||
    Object.values(authRoutes).some((route) => pathname === route)
  )
}
