import { type CreditCard } from '@/services/CreditCards/types'
import {
  type PaymentMethod,
  PaymentOption,
} from '@/services/PaymentMethods/types'

export const toPaymentMethod = (creditCard: CreditCard): PaymentMethod => {
  return {
    ...creditCard,
    paymentMethodId: `${PaymentOption.credit_card}-${String(creditCard.id)}`,
    option: PaymentOption.credit_card,
  }
}
