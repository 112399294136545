import { queryOptions, useQuery } from '@tanstack/react-query'
import { fetchAddresses } from '@/services/Addresses/fetchers'
import { useUserId } from '@/services/User/hooks'
import { type QueryOptions } from '@/utils/dataFetching/types'

export const getAddressesQueryKey = () => ['Addresses'] as const
export type AddressesQueryKey = ReturnType<typeof getAddressesQueryKey>

export const addressQueryOptions = queryOptions({
  queryKey: getAddressesQueryKey(),
  queryFn: fetchAddresses,
  staleTime: Infinity,
})

export const useQueryAddresses = (options?: QueryOptions) => {
  const userId = useUserId()
  return useQuery({
    ...addressQueryOptions,
    enabled: Boolean(userId),
    initialData: [],
    ...options,
  })
}

useQueryAddresses.getKey = getAddressesQueryKey
