import clone from 'lodash/clone'
import keyBy from 'lodash/keyBy'
import {
  type FeatureFlagKey,
  type FeatureFlags,
} from '@/services/FeatureFlags/types'
import { defaultFlags } from '@/services/FeatureFlags/constants'
import { type FeatureFlagsQuery } from '@/services/FeatureFlags/graphql/featureFlags.generated'

export const isFlagTracked = (key: string): key is FeatureFlagKey => {
  // We're correctly checking for the existence of a key, we can ignore this TS error.
  // @ts-expect-error TS7053
  return typeof defaultFlags[key] === 'boolean'
}

/**
 * Transform GraphQL FeatureFlags response into easy-to-use key-value map
 * @param data GraphQL response
 * @param has_user indicates if we have user id in context
 * @param isGuest forces all values to be false, used when user is guest
 */
export const cleanFlags = (
  data: FeatureFlagsQuery | undefined,
  has_user: boolean,
  isGuest?: boolean
): FeatureFlags => {
  const flags: FeatureFlags = clone(defaultFlags)

  const serverFlags = has_user
    ? keyBy(data?.FeatureFlagGetFlagsWithContext?.FeatureFlags, 'Name')
    : keyBy(data?.FeatureFlagGetFlags?.FeatureFlags, 'Name')

  // use default flags if response doesn't match expected shape
  if (
    !serverFlags ||
    typeof serverFlags !== 'object' ||
    Object.keys(serverFlags).length === 0
  ) {
    return flags
  }

  for (const key in flags) {
    const value = serverFlags[key]?.Value

    // This function basically checks for existence of each flag with its key, scroll above to have a look
    if (!isFlagTracked(key)) continue
    // We want to make sure all feature flags are false for guest experience.
    if (isGuest) {
      flags[key] = false
    }
    // Feature flag service returns "null" when off, "true" when on.
    // This is a graphql-mesh problem which makes everyone sad
    else if (value === true || value === null) {
      flags[key] = value !== null
    }
  }

  return flags
}
