import { useMutation, useQueryClient } from '@tanstack/react-query'
import { apiPost } from '@/utils/dataFetching'
import {
  type IdentityRequestSource,
  type RegulationCategoryStateAndReason,
} from '@/services/DataPrivacy/types'
import { useUserId } from '@/services/User/hooks'
import { restrictionsQueryOptions } from '@/services/DataPrivacy/queries'
import { isOnServer } from '@shared/constants/util'

export const useMutationSendOptOutRequest = <
  TRequestSource extends IdentityRequestSource
>() => {
  const userId = useUserId()

  return useMutation({
    mutationFn: (
      requestParams: TRequestSource extends 'web-restriction'
        ? RegulationCategoryStateAndReason & { request_source: TRequestSource }
        : { request_source: TRequestSource }
    ) => {
      return apiPost({
        config: {
          url: 'identitywf/v1/request',
          data: {
            ...requestParams,
            user_id: String(userId),
            right_type: 'do_not_share_sell_ta',
          },
        },
        fetcherName: 'useMutationSendOptOutRequest',
      })
    },
  })
}

export const useMutationHandleOptOutRestrictions = (
  onOptOutNotRequired?: () => Promise<void>
) => {
  const userId = useUserId()
  const queryClient = useQueryClient()
  const { mutateAsync: sendOptOutRequest } = useMutationSendOptOutRequest()

  const handleOptOutRequests = (params: RegulationCategoryStateAndReason) => {
    if (!isOnServer()) window.SHIPT_DO_NOT_SHARE_SELL_TA = true
    if (userId) {
      sendOptOutRequest({ ...params, request_source: 'web-restriction' })
    }
    return queryClient.invalidateQueries()
  }

  return useMutation({
    mutationFn: async (
      params: Parameters<typeof restrictionsQueryOptions>[0]
    ) => {
      const restrictions = await queryClient.fetchQuery(
        restrictionsQueryOptions(params)
      )
      return restrictions.find((r) => r.opt_out === 'do_not_share_sell_ta')
    },
    onSuccess: async (doNotShareSellTaRestriction) => {
      if (doNotShareSellTaRestriction?.active) {
        return handleOptOutRequests({
          regulation_category: doNotShareSellTaRestriction.regulation_category,
          regulation_reason: doNotShareSellTaRestriction.regulation_reason,
          regulation_state: doNotShareSellTaRestriction.regulation_state,
        })
      } else {
        return onOptOutNotRequired?.()
      }
    },
    onError: () => {
      return handleOptOutRequests({
        regulation_state: '',
        regulation_category: '',
        regulation_reason: 'service_error' as const,
      })
    },
  })
}
