import { queryOptions, useQuery } from '@tanstack/react-query'
import { fetchCreditCard } from '@/services/CreditCards/fetchers'
import { selectedPaymentMethodsAtom } from '@/state/CheckoutPreferences/atoms'
import { useEffect } from 'react'
import { onSuccessCreditCards } from '@/services/CreditCards/utils'
import { useStore } from 'jotai'
import { useUserId } from '@/services/User/hooks'

export const creditCardsQueryOptions = queryOptions({
  queryKey: ['Credit Card'] as const,
  queryFn: fetchCreditCard,
})

export const useQueryCreditCards = () => {
  const jotaiStore = useStore()
  const userId = useUserId()

  const query = useQuery({
    ...creditCardsQueryOptions,
    initialData: { credit_cards: [] },
    staleTime: Infinity,
    enabled: Boolean(userId),
  })
  const { data } = query

  /**
   * update the selected payment method in the jotai store whenever there are
   * credit cards returned from the query and the selected payment method has
   * not been set in the selectedPaymentMethodsAtom atom
   * (ie: paying for a membership via /delivery-options route)
   */

  useEffect(() => {
    if (!data?.credit_cards?.length) return

    if (!jotaiStore.get(selectedPaymentMethodsAtom)?.length) {
      onSuccessCreditCards({ creditCards: data.credit_cards, jotaiStore })
    }
  }, [jotaiStore, data.credit_cards])

  return query
}
