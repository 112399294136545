import {
  type QueryClient,
  type QueryFunctionContext,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { fetchUser, fetchUserGraphqlQuery } from '@/services/User/fetchers'
import { useSession } from '@/utils/authentication/hooks'
import { type FetchUserIfAuthOnSSR, type User } from '@/services/User/types'
import { type QueryOptions } from '@/utils/dataFetching/types'
import { hasServerAuthData } from '@/utils/authentication/client/legacy/utils'
import { getAddressesQueryKey } from '@/services/Addresses/queries'
import { creditCardsQueryOptions } from '@/services/CreditCards/queries'
import { type Address } from '@/services/Addresses/types'
import { identifyUser } from '@/analytics/users'
import { getIsUserGraphqlQueryEnabled } from '@/services/User/graphql/utils'

const getUserQueryKey = () => [UserQuery] as const
const getUserQueryOptions = () => ({ staleTime: Infinity })

export const UserQuery = 'User'
export type UserQueryKey = ReturnType<typeof getUserQueryKey>

const fetchUserWithEnvironmentVariableLogic = async (
  queryClient: QueryClient,
  context: QueryFunctionContext<UserQueryKey>
): Promise<User> => {
  const user = await (getIsUserGraphqlQueryEnabled()
    ? fetchUserGraphqlQuery(context)
    : fetchUser(context))

  /**
   * We are seeding the query cache with the user's addresses and credit cards
   * so that we don't have to make additional requests to fetch them until the
   * caches are invalidated.
   */
  queryClient.setQueryData<Address[]>(
    getAddressesQueryKey(),
    user.customer_addresses
  )
  queryClient.setQueryData(creditCardsQueryOptions.queryKey, {
    credit_cards: user.credit_cards,
  })
  identifyUser(user)
  return user
}

export const useQueryUser = ({
  notifyOnChangeProps,
}: {
  notifyOnChangeProps?: QueryOptions['notifyOnChangeProps']
}) => {
  const queryClient = useQueryClient()
  const currentUser = queryClient.getQueryData<User>([UserQuery])

  const { isSessionValid } = useSession()

  return useQuery({
    notifyOnChangeProps,
    queryKey: getUserQueryKey(),
    queryFn: (context) =>
      fetchUserWithEnvironmentVariableLogic(queryClient, context),
    enabled: Boolean(isSessionValid && currentUser?.id),
    ...getUserQueryOptions(),
  })
}

useQueryUser.fetcher = fetchUserWithEnvironmentVariableLogic
useQueryUser.getKey = getUserQueryKey
useQueryUser.options = getUserQueryOptions

export const fetchUserQuery = (queryClient: QueryClient) =>
  queryClient?.fetchQuery({
    queryKey: useQueryUser.getKey(),
    queryFn: async (context) =>
      fetchUserWithEnvironmentVariableLogic(queryClient, context),
    ...useQueryUser.options(),
  })

export const fetchUserIfAuthOnSSR = async ({
  queryClient,
  ssrContext,
}: FetchUserIfAuthOnSSR) => {
  if (!hasServerAuthData(ssrContext)) return undefined
  try {
    return await fetchUserQuery(queryClient)
  } catch {
    // fail silently, not adding logging here since dataFetching already logs error for us
  }
}
