import { useCallback, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useOrderQuery } from '@/services/Order/graphql/order.generated'
import {
  LastDeliveredOrderQuery,
  useQueryLastDeliveredOrder,
} from '@/services/Orders/queries'
import { usePrevious } from '@/hooks/usePrevious'
import { getLastDeliveredOrderQueryState } from '@/services/Orders/utils'
import { type Order } from '@/services/Orders/types'
import { ModalType } from '@/constants/modalTypes'
import { ORDER_STATUS } from '@/constants/global'
import { useHandleDialog } from '@/state/Dialog/useHandleDialog'

export const useRefetchOrderAndLastOrder = () => {
  const queryClient = useQueryClient()

  return (orderId: number): Promise<void> => {
    // this is done since the ratings service and tipping service are
    // still using the aviator v1 endpoints where there are async race
    // condition issues if we refetch for the order immediately after
    // tipping or rating
    return new Promise((resolve) =>
      setTimeout(async () => {
        queryClient.invalidateQueries({
          queryKey: useOrderQuery.getKey({ order_id: orderId }),
        })
        // this must use refetchQueries instead of invalidateQueries to ensure the isStale
        // logic within useOnLastDeliveredOrder works as expected
        await queryClient?.refetchQueries({
          queryKey: [LastDeliveredOrderQuery],
        })
        return resolve()
      }, 100)
    )
  }
}

export const useOnLastDeliveredOrder = (disableModal = false) => {
  const { handleLastOrder } = useHandleLastOrder()
  const queryClient = useQueryClient()
  const { data: lastDeliveredOrder, isStale } = useQueryLastDeliveredOrder()

  /**
   * we need to use usePrevious to determine if the query was stale the previous render
   * since the query will be stale the first time it is fetched or when the query becames
   * stale due to the staleTime used for useQueryLastDeliveredOrder
   */
  const previousIsStale = usePrevious(isStale) || false

  useEffect(() => {
    if (
      !disableModal &&
      lastDeliveredOrder &&
      // we only want to handle the modal logic for the fetched last delivered order if the query was stale
      (previousIsStale ||
        // when this query is fetched on SSR, isStale will be false to begin with since the query
        // is not stale on the server. However, we still want to handle the modal logic for the fetched
        // last delivered order on the client. To do this, we check if the dataUpdateCount is 1 which means
        // the query was fetched on the first time.
        getLastDeliveredOrderQueryState(queryClient)?.dataUpdateCount === 1)
    ) {
      handleLastOrder(lastDeliveredOrder)
    }
  }, [
    lastDeliveredOrder,
    previousIsStale,
    queryClient,
    disableModal,
    handleLastOrder,
  ])
}

export const useHandleLastOrder = () => {
  const { openDialog } = useHandleDialog()

  const handleLastOrder = useCallback(
    (order: Order) => {
      const { status, rating, shopper_rating, has_tipped, pretip, tippable } =
        order ?? {}
      const isNotRated = !(rating?.id || shopper_rating?.rating)
      const isEligibleForModal = status === ORDER_STATUS.DELIVERED && isNotRated

      if (isEligibleForModal) {
        if (tippable && !has_tipped && !pretip) {
          openDialog(ModalType.TIP_DEFAULT_MODAL)
        } else if (has_tipped && !shopper_rating?.rating) {
          openDialog(ModalType.RATE_DEFAULT_MODAL, {
            hasBackNavigation: false,
            order,
          })
        }
      }
      return isEligibleForModal
    },
    [openDialog]
  )

  return {
    handleLastOrder,
  }
}
