import { apiGet } from '@/utils/dataFetching'
import { API_URL } from '@/utils/dataFetching/apiUrl'
import { type CreditCardsResponse } from '@/services/CreditCards/types'
import { type QueryFunctionContext } from '@tanstack/react-query'

export const fetchCreditCard = (context: QueryFunctionContext) =>
  apiGet<CreditCardsResponse>({
    config: {
      url: 'api/v1/credit_cards',
      headers: {
        // Had to leave a custom header for this case as it was essential for the api to revert back the response,
        // otherwise we were seeing cors issue. It did add this header to the api but added a few others as well,
        // which were not required so maybe they were overriding the type or something. Hence just passing this header.
        Accept: 'application/json',
      },
    },
    options: { apiUrl: API_URL.MEMBER_API },
    context,
    fetcherName: 'fetchCreditCard',
  })
