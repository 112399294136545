import { breakpoints } from '@/styles/media'
import styled, { css } from 'styled-components'
import { ColorButton } from '@/elements/Buttons/ButtonColors'

const PillButton = css`
  font-size: 0.8125rem;
  border-radius: 2.5rem;
  padding: 0.3125rem 0.9375rem;

  @media ${breakpoints.medium} {
    padding: 0.5625rem 0.9375rem;
  }
`

//Color Buttons

/**
 * @deprecated This button isn't supported in the design system currently. Please reach out to the design system team
 * so that we can review the use case and determine if this button should be added to the design system as a new concept.
 *
 * **Purpose:**
 * This component is designed to allow flexibility in the colors that we use for buttons. Normally we use
 * one of the main buttons above, but in special cases like member preferences we want buttons in one of
 * the other DLS colors (Ex. Purple500).
 *
 * **NOTE:**
 * Please verify that the color used meets applicable A11y guidelines when using this component
 *
 * Sample Implementation A: set props at the style component
 * ```
 * const YesButton = styled(ColorPillButton).attrs({color: 'purple500'})`
 *  // additional styles here
 * `
 * <YesButton />
 * ```
 * Sample Implementation B: set props as an attribute in the markup
 * ```
 * const YesButton = styled(ColorPillButton)`
 *  // additional styles here
 * `
 * <YesButton color='purple500' />
 * ```
 *
 * **Figma Link:**
 * [Click here to view the Figma file](https://www.figma.com/file/SWPhdIsgPAHmghzvdC2Yzs/Member-DLS-2021?node-id=145%3A1125)
 */
/**
 * @deprecated Use { Button } from '@shipt/design-system-buttons' instead
 */
export const ColorPillButton = styled(ColorButton)`
  ${PillButton}
`
