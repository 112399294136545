export enum ModalType {
  ADDRESS_MODAL = 'address modal',
  ADD_AND_CREATE_LIST_DRAWER = 'add and create list drawer',
  ADD_EBT_CARD_MODAL = 'add ebt card modal',
  ADD_PRODUCT_TO_SHOPPING_LIST_MODAL = 'add product to shopping list modal',
  AGE_RESTRICTED_TERMS_MODAL = 'age restricted terms modal',
  AUTH_NOTICE_MODALS = 'auth notice modals',
  AUTO_ADD_TO_CART_AND_USUALS_LAUNCH_DIALOG = 'auto add to cart and usuals launch dialog',
  AUTO_ADD_TO_CART_INFO_DRAWER = 'auto add to cart info drawer',
  AUTO_ATC_FREQUENCY_DRAWER = 'auto atc frequency drawer',
  BUY_AGAIN_DRAWER = 'buy again drawer',
  BUY_AGAIN_FILTER_MODAL = 'buy again filter modal',
  CANCELLATION_CONFIRMATION_MODAL = 'cancellation confirmation modal',
  CHECKOUT_MEMBERSHIP_CHECKBOX_MODAL = 'checkout membership checkbox modal',
  CHECKOUT_MEMBERSHIP_POP_UP_MODAL = 'checkout membership pop up modal',
  CHOOSE_STORE_MODAL = 'choose store modal',
  CIRCLE_360_INFO_MODAL = 'circle 360 info modal',
  CMS_PROMOTION_TERMS_DRAWER = 'cms promotion terms drawer',
  CONFIRMATION_MODAL = 'confirmation modal',
  CONTACT_SUPPORT_MODAL = 'contact support modal',
  COUPONS_FILTER_MODAL = 'coupons filter modal',
  COUPON_MODAL = 'coupon modal',
  COUPON_TERMS_MODAL = 'coupon terms modal',
  CREDITS_BALANCE_MODAL = 'credits balance modal',
  CREDITS_MODAL = 'credits modal',
  DEEP_LINK_CHANGE_STORE_MODAL = 'deep link change store modal',
  DELIVERY_PLANS_PURCHASE_MODAL = 'delivery plans purchase modal',
  EBT_HOW_IT_WORKS_MODAL = 'ebt_how_it_works_modal',
  EBT_PIN_MODAL = 'ebt_pin_modal',
  EXISTING_MEMBER_MODAL = 'existing member modal',
  EXPRESS_CHECK_OUT_MODAL = 'express check out modal',
  GENERIC_ERROR_MODAL = 'generic error modal',
  GIFT_CARD_ORDER_OVERVIEW_MODAL = 'gift card order overview modal',
  GIFT_CARD_PURCHASE_CONFIRMATION_DIALOG = 'gift card purchase confirmation dialog',
  GLOBAL_ADDRESS_MODAL = 'global address modal',
  GLOBAL_CAMPAIGN_DRAWER = 'global campaign drawer',
  GLOBAL_HOMEPAGE_CART_DRAWER = 'global homepage cart drawer',
  GLOBAL_PROMOTION_TERMS_MODAL = 'global promotion terms modal',
  GLOBAL_STORE_SELECTION_DRAWER = 'global store selection drawer',
  GUEST_ADDRESS_CONFIRMATION_MODAL = 'guest address confirmation modal',
  GUEST_ADDRESS_MODAL = 'guest address modal',
  HEALTHY_CHOICE_BALANCE_MODAL = 'healthy choice balance modal',
  INFORMATION_MODAL = 'information modal',
  ITEMS_IN_ORDER_DRAWER = 'items_in_order_drawer',
  LIST_ENTRYPOINT_MODAL = 'lists_entrypoint_modal',
  MEMBERSHIP_CHANGE_MODAL = 'membership change modal',
  MEMBERSHIP_PURCHASE_MODAL = 'membership purchase modal',
  MEMBERSHIP_SELECTION_MODAL = 'membership selection modal',
  MEMBERSHIP_SUCCESS_MODAL = 'membership success modal',
  MEMBER_EXCLUSIVE_OFFER_MODAL = 'member exclusive offer modal',
  MOBILE_SEARCH_MODAL = 'mobile search modal',
  MORE_LIKE_THIS_ITEMS_DRAWER = 'more like this items drawer',
  NEW_LIST_MODAL = 'new_list_modal',
  ORDER_PRODUCT_FEEDBACK_MODAL = 'order product feedback modal',
  ORDER_RESCHEDULE_MODAL = 'order reschedule modal',
  OTHER_PLANS_DRAWER = 'other plans to choose drawer',
  OUT_OF_STOCK_MODAL = 'out of stock modal',
  PAYMENT_METHOD_MODAL = 'payment method modal',
  PREFERRED_SHOPPER_FIRST_MODAL = 'preferred shopper first modal',
  PREFERRED_SHOPPER_ONBOARDING_MODAL = 'preferred shopper onboarding modal',
  PREFERRED_SHOPPER_PAIRING_MODAL = 'preferred shopper pairing modal',
  PREFERRED_SHOPPER_PENDING_MODAL = 'preferred shopper pending modal',
  PRODUCT_INSTRUCTIONS_MODAL = 'product instructions modal',
  PRODUCT_NOTES_AND_BACKUP_DRAWER = 'product notes and backup drawer',
  PROFILE_FORM_MODAL = 'profile form modal',
  PROMOTION_ALERT_MODAL = 'promotion alert modal',
  PROMOTION_STORES_DRAWER = 'promotion products stores drawer',
  PROMOTION_TERMS_MODAL = 'promotion terms modal',
  PURCHASE_CONFIRMATION_MODAL = 'purchase confirmation modal',
  RATE_DEFAULT_MODAL = 'rating order and shopper modal',
  REACTIVATION_MODAL = 'reactivation modal',
  RECEIPT_MODAL = 'receipt modal',
  REDEEM_GIFT_CARD_MODAL = 'redeem gift card modal',
  REPORT_ITEM_EDUCATIONAL_MODAL = 'report item education modal',
  REWARDS_MODAL = 'rewards modal',
  SAVINGS_INFORMATION_MODAL = 'savings information modal',
  SEARCH_FILTERS_DRAWER = 'search filters drawer',
  SEARCH_FILTER_MODAL = 'search filter modal',
  SELECT_PAYMENT_TYPE_MODAL = 'select payment type modal',
  SHIPT_AI_LEARN_MORE_MODAL = 'shiptai learn more modal',
  SNAP_EBT_ADDITIONAL_CHARGE_MODAL = 'snap EBT additional charge modal',
  SNAP_EBT_CARD_VERIFICATION_MODAL = 'snap EBT card verification modal',
  SNAP_EBT_MEMBERSHIP_ENTRY_MODAL = 'snap ebt membership entry modal',
  SNAP_EBT_PURCHASE_MODAL = 'snap EBT purchase modal',
  SPECIAL_REQUEST_MODAL = 'special request modal',
  STORES_IN_ZIPCODE_MODAL = 'stores in zipcode modal',
  STORE_INFO_DRAWER = 'store info and delivery times drawer',
  STORE_SELECTION_DRAWER = 'store selection drawer',
  STUDENT_PURCHASE_MODAL = 'student_purchase_modal',
  STUDENT_REVERIFICATION_SUCCESS_MODAL = 'student reverification success modal',
  STUDENT_VERIFICATION_CODE_MODAL = 'student verification code modal',
  STUDENT_VERIFICATION_EMAIL_MODAL = 'student verification email modal',
  STUDENT_VERIFICATION_HOW_IT_WORKS_MODAL = 'student verification how it works modal',
  STUDENT_VERIFICATION_SUCCESS_MODAL = 'student verification success modal',
  SUBSTITUTION_PREFERENCES_INFO_MODAL = 'substitution preferences info modal',
  TARGET_RED_CARD_MASTERCARD_MODAL = 'target red card mastercard modal',
  TIP_DEFAULT_MODAL = 'tip default modal',
  TIP_YOUR_SHOPPER_MODAL = 'tip your shopper modal',
  TWO_FACTOR_SETTING_MODAL = 'two factor setting modal',
  TWO_FACTOR_VERIFY_CODE_MODAL = 'two factor verify code modal',
  UNIVERSAL_SEARCH_FILTER_MODAL = 'universal search filter modal',
  UPDATE_EBT_CARD_MODAL = 'update ebt card modal',
  UPGRADE_SUCCESS_MODAL = 'upgrade success modal',
  USUALS_DRAWER = 'usuals drawer',
  VARIATIONS_QUICK_ADD_MODAL = 'variations quick add modal',
  VIEW_SHOP_PROGRESS_DRAWER = 'view shop progress drawer',
  VISA_CHECK_BENEFITS_MODAL = 'visa check benefits modal',
  VISA_ENROLLMENT_CONFIRMATION_MODAL = 'visa enrollment confirmation modal',
  VISA_NO_BENEFITS_MODAL = 'visa no benefits modal',
  VISA_SPECIFIC_BENEFITS_MODAL = 'visa specific benefits modal',
  WELCOME_MODAL = 'welcome modal',
  ZIPCODE_FORM_MODAL = 'zipcode form modal',
  ZIP_NOT_COVERED_MODAL = 'zip not covered modal',
}
