/// <reference types="@welldone-software/why-did-you-render" />
// used for whyDidYouRender
// eslint-disable-next-line no-restricted-imports
import React from 'react'

if (
  process.env.NODE_ENV === 'development' &&
  process.env.NEXT_PUBLIC_TRACK_RENDERS &&
  typeof window !== 'undefined'
) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')

  whyDidYouRender(React, {
    collapseGroups: true,
    trackAllPureComponents: true,
  })
}
