import { type QueryFunctionContext } from '@tanstack/react-query'
import {
  type AristotleExperiments,
  type AristotleResponse,
} from '@/services/Experiments/types'
import { apiGet } from '@/utils/dataFetching'
import { type ExperimentsQueryKey } from '@/services/Experiments/queries'
import { removeFalseyValues } from '@/utils/removeFalseyValues'
import { ExperimentType } from '@/services/Experiments/constants'
import { getObjectEntries } from '@/utils/types'

export const fetchAllExperiments = async (
  context: QueryFunctionContext<ExperimentsQueryKey>
): Promise<AristotleExperiments> => {
  const [, { storeParams, has_placed_first_order, has_purchased }] =
    context.queryKey
  const signed_in = Boolean(storeParams?.user_id)
  // Aristotle doesn't like null query params
  const params = {
    ...removeFalseyValues(storeParams),
    signed_in,
    has_placed_first_order,
    has_purchased,
  }

  const response = await apiGet<AristotleResponse>({
    config: { url: 'aristotle/v2/all', params },
    context,
    fetcherName: 'fetchAllExperiments',
  })
  const experimentTypeValuesSet = new Set(Object.values(ExperimentType))

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we know it is type AristotleExperiments
  return Object.fromEntries(
    getObjectEntries(response.variations).filter(([key]) =>
      experimentTypeValuesSet.has(key)
    )
  ) as AristotleExperiments
}
