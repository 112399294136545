import Head from 'next/head'
import { type ReactNode } from 'react'

export const CMSPreviewContainer = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Preview</title>
      </Head>
      {children}
    </>
  )
}
