import { useFetchUserQuery } from '@/services/User/hooks'
import { useCallback } from 'react'
import { useSession } from '@/utils/authentication/hooks'
import { customClaims } from '@/serverUtils/auth/constants'

export const useSyncUser = () => {
  const fetchUserQuery = useFetchUserQuery()
  const { isSessionLoading, isSessionValid, checkSession, sessionData } =
    useSession()
  const isFullyAuthenticated = sessionData
    ? Boolean(sessionData[customClaims.ONBOARDED])
    : isSessionValid

  return useCallback(async () => {
    await checkSession()
    // This block repopulates the customer state if auth data is present,
    if (isSessionLoading) return
    if (isFullyAuthenticated) fetchUserQuery()
  }, [fetchUserQuery, isSessionLoading, isFullyAuthenticated, checkSession])
}
