import { toPaymentMethod } from '@/services/PaymentMethods/utils'
import { selectedPaymentMethodsAtom } from '@/state/CheckoutPreferences/atoms'
import { type CreditCard } from '@/services/CreditCards/types'
import { type JotaiStore } from '@/state/types'
import { PaymentOption } from '@/services/PaymentMethods/types'

export const getDefaultCreditCard = (credit_cards?: CreditCard[]) => {
  if (!credit_cards?.length) return
  return credit_cards.find((card) => card.default_source)
}

type SuccessProps = {
  jotaiStore: JotaiStore
  creditCards: CreditCard[]
}
export const onSuccessCreditCards = ({
  jotaiStore,
  creditCards,
}: SuccessProps) => {
  if (!creditCards) return
  const card = getDefaultCreditCard(creditCards)
  if (!card) return
  jotaiStore.set(selectedPaymentMethodsAtom, [
    { ...toPaymentMethod(card), type: 'dynamic' },
  ])
}

type EbtSuccessProps = {
  jotaiStore: JotaiStore
  ebtCard: CreditCard
}
export const onSuccessEbtCard = ({ jotaiStore, ebtCard }: EbtSuccessProps) => {
  if (!ebtCard) return
  const fixedAmount =
    jotaiStore
      .get(selectedPaymentMethodsAtom)
      .find((card) => card.option === PaymentOption.credit_card && card.is_ebt)
      ?.amount || 0
  const nonEBTCards = jotaiStore
    .get(selectedPaymentMethodsAtom)
    .filter(
      (card) => card.option === PaymentOption.payment_wallet || !card.is_ebt
    )
  jotaiStore.set(selectedPaymentMethodsAtom, [
    ...nonEBTCards,
    { ...toPaymentMethod(ebtCard), type: 'fixed', amount: fixedAmount },
  ])
}
