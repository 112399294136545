import { IconButton } from '@shipt/design-system-buttons'
import { TimesIcon } from '@shipt/design-system-icons'
import { Stack } from '@shipt/design-system-layouts'
import { Body, Link } from '@shipt/design-system-typography'
import { useState } from 'react'
import styled from 'styled-components'
import { getItem, setItem } from '@/utils/localStorage'
import { routes } from '@shared/constants/routes'
import { breakpoints } from '@/styles/media'
import { ClientOnly } from '@/components/ClientOnly'
import { userIsDoNotShareSellTA } from '@/services/DataPrivacy/utils'

export const OPT_OUT_DIALOG_DISMISSED = 'opt_out_dialog_dismissed'

const Dialog = () => {
  const userHasNotOptedOut = !userIsDoNotShareSellTA()
  const hasNotDismissedDialog = getItem(OPT_OUT_DIALOG_DISMISSED) !== 'true'
  const [isShown, setIsShown] = useState(
    userHasNotOptedOut && hasNotDismissedDialog
  )

  const dismissDialog = () => {
    setIsShown(false)
    setItem(OPT_OUT_DIALOG_DISMISSED, 'true')
  }

  if (!isShown) return null
  return (
    <Container>
      <TextContent size="md">
        Shipt may use the personal information we collect and disclose it to
        third parties for “targeted advertising”, “selling” or “sharing”
        purposes as described in the{' '}
        <Link
          size="md"
          href="/privacy-policy/#sales-or-sharing-of-personal-info"
        >
          Privacy Notice
        </Link>
        . Request to{' '}
        <Link size="md" href={routes.PRIVACY_RIGHTS_REQUESTS.url}>
          opt out
        </Link>
        .
      </TextContent>
      <CloseButton
        size="sm"
        variant="ghost"
        icon={TimesIcon}
        onClick={dismissDialog}
        aria-label="Dismiss opt out dialog"
      />
    </Container>
  )
}

export const OptOutDialog = () => (
  <ClientOnly>
    <Dialog />
  </ClientOnly>
)

const Container = styled(Stack)`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.gray100};
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem;
  padding-right: 2.5rem;
  z-index: 999999;
`

const TextContent = styled(Body)`
  a {
    color: ${({ theme }) => theme.purple500};
  }

  @media ${breakpoints.medium} {
    &,
    a {
      font-size: 1rem;
    }
  }
`

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;

  @media ${breakpoints.medium} {
    top: unset;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`
