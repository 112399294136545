import { useRef, useEffect } from 'react'

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T | undefined>(undefined)
  // eslint-disable-next-line use-effect-no-deps/use-effect-no-deps -- Lack of dep array is intentional as we want this "every render" behavior
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
