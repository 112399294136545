import { useCallback } from 'react'
import { track } from '@/analytics/trackingQueue'
import { AnalyticsEvent } from '@shipt/analytics-member-web'
import { urlPathnameToLocationName } from '@/analytics/utils'
import { useMetroId } from '@/services/User/hooks'
import { useSingleDeliveryFee } from '@/services/DeliveryPlans/hooks'
import { getBenefitDetails } from '@/constants/visa'
import {
  type Subscription,
  type SubscriptionPlan,
  SubscriptionId,
  type SubscriptionResponse,
  MembershipInterval,
} from '@/services/MembershipPlan/types'
import { trackFirstPurchased } from '@/analytics/users'
import {
  getMembershipType,
  getSubscriptionInformation,
} from '@/components/Membership/utils'
import {
  epochSecondsToISO,
  toMembershipPlan,
} from '@/services/MembershipPlan/utils'
import { type VisaBenefitDetails } from '@/services/VisaBenefits/types'
import { trackElementClicked } from '@/analytics/element'
import { PackagePlanType } from '@/services/DeliveryPlans/types'

type PlanTypeViewedProps = {
  name: string
  isPromo?: boolean
  upgradeTerm?: string
  isTrial?: boolean
  displaySublocation?: string
}

type PlanTypeClickedProps = {
  name: string
  price?: number
  isPromo?: boolean
  isTrial?: boolean
  planType?: string
  upgradeTerm?: string
  planId?: string
  displaySublocation?: string
}

export const useTrackPlanType = (isModal = true) => {
  const displayType = isModal ? 'modal' : 'page'
  const metroId = useMetroId()

  const trackPlanTypeViewed = useCallback(
    (eventProperties: PlanTypeViewedProps) => {
      const { name, isPromo, upgradeTerm, isTrial, displaySublocation } =
        eventProperties
      track({
        eventName: AnalyticsEvent.PlanTypeViewed,
        properties: {
          location: urlPathnameToLocationName(),
          display_type: displayType,
          content: name,
          is_promo: Boolean(isPromo),
          upgrade_term: upgradeTerm ?? name,
          is_trial: Boolean(isTrial),
          display_sublocation: displaySublocation,
        },
      })
    },
    [displayType]
  )

  const trackPlanTypeClicked = useCallback(
    (eventProperties: PlanTypeClickedProps) => {
      const {
        name,
        price,
        isPromo,
        isTrial,
        planType,
        upgradeTerm,
        planId,
        displaySublocation,
      } = eventProperties
      track({
        eventName: AnalyticsEvent.PlanTypeClicked,
        properties: {
          location: urlPathnameToLocationName(),
          metro_id: metroId,
          display_type: displayType,
          plan_id: planId ?? '',
          plan_id_billing_amount: price ?? 0,
          is_promo: Boolean(isPromo),
          is_trial: Boolean(isTrial),
          content: planType ?? PackagePlanType.STANDARD,
          upgrade_term: upgradeTerm ?? name,
          display_sublocation: displaySublocation,
        },
      })
    },
    [displayType, metroId]
  )

  return {
    trackPlanTypeViewed,
    trackPlanTypeClicked,
  }
}

export const useTrackSingleDelivery = (isModal?: boolean) => {
  const { trackPlanTypeViewed, trackPlanTypeClicked } =
    useTrackPlanType(isModal)
  const { singleDeliveryFee } = useSingleDeliveryFee()
  const name = 'one_time_ppo'

  const trackSingleDeliveryViewed = useCallback(() => {
    trackPlanTypeViewed({ name })
  }, [trackPlanTypeViewed])

  const trackSingleDeliveryClicked = useCallback(() => {
    trackElementClicked({
      type: isModal ? 'modal' : 'page',
      content: name,
      message_goal: 'Membership Selection',
    })
    trackPlanTypeClicked({
      name,
      price: singleDeliveryFee * 100,
      planType: 'ppo',
    })
  }, [isModal, singleDeliveryFee, trackPlanTypeClicked])

  return { trackSingleDeliveryViewed, trackSingleDeliveryClicked }
}

// Visa must return monthly for all plans in Stripe by default
// so we need to manually calculate the Visa plan interval and count
const getVisaInterval = (benefits: VisaBenefitDetails) => {
  const duration = getBenefitDetails(benefits?.type)?.durationMonths ?? 1
  const isYearBased = duration > 12

  return {
    interval: isYearBased ? MembershipInterval.year : MembershipInterval.month,
    intervalCount: isYearBased ? duration / 12 : duration,
  }
}

const useVisaIntervalName = (benefits: VisaBenefitDetails): string => {
  const { interval, intervalCount } = getVisaInterval(benefits)
  const intervalName = interval === MembershipInterval.year ? 'yr' : 'mo'
  return `_${intervalCount}${intervalName}`
}

export const useTrackVisaPlanType = (
  pendingBenefit: VisaBenefitDetails,
  isModal?: boolean
) => {
  const { trackPlanTypeViewed } = useTrackPlanType(isModal)
  const intervalName = useVisaIntervalName(pendingBenefit)
  const name = `visa_free_membership${intervalName}`

  const trackVisaPlanViewed = useCallback(() => {
    trackPlanTypeViewed({
      name,
      isPromo: true,
    })
  }, [name, trackPlanTypeViewed])

  return { trackVisaPlanViewed }
}

export enum PlanSublocation {
  VISA_CALLOUT = 'visa_callout',
  BANNER = 'banner',
  PROMO_CARD = 'promo_card',
  PLAN_DETAILS = 'plan_details',
  DELIVERY_OPTIONS = 'delivery_options',
  PRICING_PAGE = 'pricing_page',
  CHECKBOX = 'checkbox',
  TRIAL_AUTO_DIRECT = 'trial_auto_direct',
}

export enum SpecialtyPlanSublocation {
  DRAWER = 'drawer',
  PLAN_SECTION = 'plan_section',
  DISCOUNTED_PAGE_BUTTON = 'discounted_page_button',
}

type PlanStartedProps = {
  subscription?: Maybe<SubscriptionResponse>
  from?: PlanSublocation | SpecialtyPlanSublocation
  isModal?: boolean
  isGift?: boolean
  discount?: number
  promoCode?: string
  visaBenefits?: VisaBenefitDetails
  index?: number
  upgradeTerm?: string
}

const formatPlanDate = (time?: Maybe<number>) => epochSecondsToISO(Number(time))

export const formatPlanType = (
  plan: SubscriptionPlan | Subscription,
  visaBenefits?: VisaBenefitDetails
) => {
  const { id, interval } = plan ?? {}
  const intervalCount =
    'intervalCount' in plan ? plan.intervalCount : plan?.interval_count
  const membershipType = getMembershipType(
    interval ?? MembershipInterval.year,
    intervalCount ?? 1
  ).toLocaleLowerCase()

  // default to annual plan
  let planType: string = id ?? SubscriptionId.GROCERY_YEARLY
  let planTerm = membershipType === 'membership' ? 'annual' : membershipType

  const isVisa = planType === SubscriptionId.VISA_MONTHLY && visaBenefits
  const isStudent = planType === SubscriptionId.STUDENT_MONTHLY
  const isSnapEBT = planType === SubscriptionId.SNAP_EBT_MONTHLY

  if (isVisa) {
    const card = getBenefitDetails(visaBenefits?.type)

    const duration = card.durationText
    const discountedDuration = card?.discountedDurationText

    planTerm = `${duration}_free`
    if (discountedDuration) {
      planTerm = `${planTerm}_${discountedDuration}_50%`
    }

    planType =
      (card?.durationMonths ?? 1) % 12 === 0 ? 'visa_yearly' : 'visa_monthly'
  } else if (isStudent) {
    planTerm = 'Student'
    planType = 'Student'
  } else if (isSnapEBT) {
    planType = 'ebt'
    planTerm = 'ebt'
  }

  // if plan is a monthly type, we only return grocery_monthly without any pricing info
  if (planType.indexOf(SubscriptionId.GROCERY_MONTHLY) === 0) {
    planType = SubscriptionId.GROCERY_MONTHLY
  }

  return {
    planTerm: planTerm.toLocaleLowerCase().replace(/ /g, '_'),
    planType: planType.toLocaleLowerCase(),
  }
}

export const trackPlanStarted = ({
  subscription,
  from,
  isModal = true,
  isGift = false,
  discount,
  promoCode,
  visaBenefits,
  index,
  upgradeTerm,
}: PlanStartedProps) => {
  if (!subscription) return
  const {
    current_period_start,
    current_period_end,
    plan,
    trial_start,
    trial_end,
  } = subscription ?? {}
  const location = urlPathnameToLocationName()
  const planStart = formatPlanDate(current_period_start)
  const planEnd = formatPlanDate(current_period_end)
  const trialStart = formatPlanDate(trial_start)
  const trialEnd = formatPlanDate(trial_end)
  const isTrial = Boolean(trialStart || trialEnd)
  const { planType, planTerm } = formatPlanType(
    subscription?.plan ?? {},
    visaBenefits
  )
  const isVisa = subscription?.plan?.id === SubscriptionId.VISA_MONTHLY
  const billingAmount = isVisa ? 0 : plan?.amount ?? 0

  track({
    eventName: AnalyticsEvent.PlanStarted,
    properties: {
      location,
      display_sublocation: from,
      display_format: isModal ? 'modal' : 'page',
      list_index: index,
      plan_term: planTerm,
      plan_type: planType,
      billing_amount: billingAmount,
      billing_amount_dollars: billingAmount,
      ...(discount && {
        discount_amount: discount,
      }),
      ...(promoCode && {
        promotion_code: promoCode,
      }),
      plan_start_date: planStart,
      plan_end_date: planEnd,
      is_trial: isTrial,
      ...(isTrial && {
        trial_start_date: trialStart,
        trial_end_date: trialEnd,
      }),
      is_gifted: isGift,
      upgrade_term: upgradeTerm,
      subscription_id: plan?.id || '',
    },
  })
}

export const trackPlanStartedAndFirstPurchased = ({
  subscription,
  from,
  isModal,
  isGift,
  discount,
  visaBenefits,
  index,
  upgradeTerm,
}: PlanStartedProps) => {
  if (!subscription) return

  trackPlanStarted({
    subscription,
    from,
    isModal,
    isGift,
    discount,
    visaBenefits,
    index,
    upgradeTerm,
  })
  trackFirstPurchased(subscription?.plan)
}

export const trackPlanCancelled = (
  subscription: Subscription,
  visaBenefits?: VisaBenefitDetails
) => {
  if (!subscription) return
  const { startDate, renewalDate, trialStart, trialEnd, id } =
    subscription ?? {}
  const isTrial = Boolean(trialStart || trialEnd)
  const { planTerm, planType } = formatPlanType(subscription, visaBenefits)

  track({
    eventName: AnalyticsEvent.PlanCancelled,
    properties: {
      plan_term: planTerm,
      plan_type: planType,
      plan_start_date: startDate,
      plan_end_date: renewalDate,
      is_trial: isTrial,
      is_gifted: false,
      ...(isTrial && {
        trial_start_date: trialStart,
        trial_end_date: trialEnd,
      }),
      subscription_id: id,
    },
  })
}

export const trackPlanReactivated = (
  subscriptionResponse: SubscriptionResponse,
  sublocation?: string
) => {
  const location = urlPathnameToLocationName()
  const subscription = toMembershipPlan(subscriptionResponse)
  const { planType } = getSubscriptionInformation(subscription)

  track({
    eventName: AnalyticsEvent.PlanReactivated,
    properties: {
      plan_end_date: subscription.renewalDate,
      plan_start_date: subscription.startDate,
      plan_type: planType,
      location: sublocation || location,
    },
  })
}
