export const ignoredMessages = [
  'Experiment JS Client',
  'Username has already been taken',
  'Invalid Username or Password',
  'An order cannot be updated within one hour of the delivery window',
  'Preflight response is not successful',
  'This request is unauthorized. Please log in to your account.',
  'Network Error',
  'The network connection was lost',
  'Failed to fetch',
  'Load failed',
  'ineligible for membership upgrade',
  'Address not in service area',
  'Please enter a valid address',
  'landing page not found',
  'Product currently unavailable in your area',
  'read ECONNRESET',
  'Product not found',
  'Selected store not available in provided zip code',
  'Service not available in your area',
  'Could not find product',
  'Card The zip code you supplied failed validation..',
  'password reset token expired',
  "Card can't be deleted while it is being used for an active order or tip.",
  'the retailer does not service the provided delivery address',
  'Sorry! You cannot add alcoholic products to an order after it is placed',
  'An order cannot be updated because shopping is in progress',
  'Account is locked because of too many incorrect login attempts',
  'Payment information does not match the card on file',
  'This delivery time is no longer available',
  'CVC is invalid',
  'Please contact customer service to complete this order at',
  'Recent passwords cannot be reused',
  'An order cannot be cancelled within one hour of the delivery window',
]

export const releaseStage = process.env.NEXT_PUBLIC_ENV
