import { isLocal } from '@/utils/environment'
import Bugsnag, { type Event, type BrowserConfig } from '@bugsnag/js'
import {
  ignoredMessages,
  releaseStage,
} from '@/lib/ThirdPartyScripts/error_tracking_config'
import { setGlobalContextFromRoute } from '@/utils/appStart/setGlobalContextFromRoute'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { isOnServer } from '@shared/constants/util'
import { type PageRouteData } from '@/utils/setPageProps'

const ignoredMessagesRegex = ignoredMessages.map(
  (message) => new RegExp(message.toLocaleLowerCase(), 'i')
)

export function errorHandler(event: Event) {
  const errorMessage = event.originalError.message
  if (errorMessage?.includes('Minified React error')) {
    // group hydration errors by page and error type e.g. Minified React error #418;
    const reactError = event.originalError.message.split(';')[0]
    const contextStr = event.context ?? 'UNKNOWN'
    event.groupingHash = `${contextStr}:${reactError}`
    event.context = `${contextStr}: React Error`
  }

  if (errorMessage === 'Request aborted') {
    event.groupingHash = 'Request aborted'
  }

  if (errorMessage === 'timeout exceeded') {
    event.groupingHash = 'Timeout exceeded'
  }

  // if errorMessage matches any of the ignored messages, discard error
  return !ignoredMessagesRegex.some((pattern) => pattern.test(errorMessage))
}

let isBugsnagInitialized = false

export function startBugsnagAndSetContext(routeData: PageRouteData) {
  if (process.env.NEXT_PHASE !== 'phase-production-build' && !isOnServer()) {
    // if Bugsnag is already initialized, then we only need to handle setting
    // the route context whenever the route changes
    if (isBugsnagInitialized) {
      return setGlobalContextFromRoute(routeData)
    }

    const commonConfig: BrowserConfig = {
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY!,
      appVersion:
        process.env.NEXT_PUBLIC_VERSION_TAG || // set app version to tag if present
        process.env.NEXT_PUBLIC_COMMIT_SHA, // otherwise use commit sha
      releaseStage,
    }
    // disable annoying output when running locally
    if (isLocal) {
      commonConfig.logger = null
      commonConfig.appVersion = 'local'
    }

    const config: BrowserConfig = {
      plugins: [new BugsnagPluginReact()],
      redactedKeys: [
        'phone',
        'email',
        'addresses',
        'creditCards',
        'preferences',
        /^password$/i,
      ],
      onError: errorHandler,
      ...commonConfig,
    }
    isBugsnagInitialized = true
    Bugsnag.start(config)
    // sets route context on Bugsnag at initialization to capture crew and page information
    // bugs that occur immediately on app load (e.g. hydration) will then be tagged appropriately
    setGlobalContextFromRoute(routeData)
  }
}
