import { useUser as useAuth0User } from '@auth0/nextjs-auth0/client'
import { hasLegacyClientAuthData } from '@/utils/authentication/client/legacy/utils'
import { shouldUseNewAuth } from '@/utils/authentication/utils'

export enum SessionType {
  Legacy = 'legacy',
  AuthV2 = 'authv2',
}

/**
 * Wraps the auth0 useUser hook to provide details to the front-end
 * about session state. isSessionLoading is true whenever session
 * details are being fetched from the NextJS backend. isSessionValid
 * is only true when isSessionLoading is false and there is session data.
 *
 * For now, this hook is only useful on the front-end
 * @returns Information regarding an auth0 session on the CLIENT
 */
export const useSession = () => {
  const { user, isLoading, error, checkSession } = useAuth0User()
  if (!shouldUseNewAuth()) {
    // If using legacy auth, use hasLegacyClientAuthData()
    return {
      sessionData: undefined,
      sessionType: SessionType.Legacy,
      error: undefined,
      isSessionLoading: false,
      isSessionValid: hasLegacyClientAuthData(),
      checkSession: () => Promise.resolve(),
    }
  }
  return {
    sessionData: user,
    sessionType: SessionType.AuthV2,
    error,
    isSessionLoading: isLoading,
    isSessionValid: !isLoading && Boolean(user),
    checkSession,
  }
}
