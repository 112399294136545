import { dialogAtom } from '@/state/Dialog/atom'
import { useHandleDialog } from '@/state/Dialog/useHandleDialog'
import { useResetAtom } from 'jotai/utils'

export const useDetectPopState = () => {
  const { closeDialog } = useHandleDialog()
  const atomReset = useResetAtom(dialogAtom)

  const detectPopState = () => {
    window.onpopstate = () => {
      closeDialog()
      atomReset()
    }
  }

  return detectPopState
}
