import { type NextApiRequest, type NextApiResponse } from 'next'
import { getAuth0SDK } from '.'
import { type IncomingMessage, type ServerResponse } from 'http'
import { claimNamespace, customClaims } from '@/serverUtils/auth/constants'
import { CACHE_CONTROL_PREVENT_CACHING } from '@shared/constants/caching'

export interface GetTokenOptions {
  forceRefresh?: boolean
}

export const getSession = async (
  req: NextApiRequest | IncomingMessage,
  res: NextApiResponse | ServerResponse
) => {
  const sdk = getAuth0SDK()
  if (!sdk) {
    throw new Error('Auth0 SDK is not available. Are the env vars set?')
  }
  return sdk.getSession(req, res)
}

export const updateSession = async (
  req: NextApiRequest | IncomingMessage,
  res: NextApiResponse | ServerResponse,
  user: Record<string, unknown>,
  namespace = claimNamespace
) => {
  const sdk = getAuth0SDK()
  if (!sdk) {
    throw new Error('Auth0 SDK is not available. Are the env vars set?')
  }
  const session = await getSession(req, res)

  // Prefix all object keys on user with a namespace
  // E.g. - { foo: 'bar' } becomes { 'https://www.shipt.com/foo': 'bar' }
  const userWithNamespace = Object.fromEntries(
    Object.entries(user).map(([key, value]) => [`${namespace}${key}`, value])
  )

  return sdk.updateSession(req, res, {
    ...session,
    user: { ...session?.user, ...userWithNamespace },
  })
}

export const getIsAuthenticated = async (
  req: NextApiRequest | IncomingMessage,
  res: NextApiResponse | ServerResponse
) => {
  try {
    const session = await getSession(req, res)
    return Boolean(session?.user)
  } catch {
    return false
  }
}

export const getIsOnboarded = async (
  req: NextApiRequest | IncomingMessage,
  res: NextApiResponse | ServerResponse
) => {
  try {
    const user = (await getSession(req, res))?.user
    return Boolean(
      user?.[customClaims.ONBOARDED] && user?.[customClaims.SHIPT_USER_ID]
    )
  } catch {
    return false
  }
}

/**
 * Retrieves the accessToken from the cookie session. The token is automatically
 * refreshed if needed, and the new accessToken and refreshToken are stored
 * back in the cookie session, which is encoded back onto the response (set-cookie).
 * Returns the access token
 * @param req - The incoming request object
 * @param res - The outgoing response object
 * @param getTokenOptions - Configuration options for specifying how to fetch a token
 * @returns {Promise} Promise that resolves with the accessToken as a string
 */
export const getAccessTokenFromSession = async (
  req: NextApiRequest | IncomingMessage,
  res: NextApiResponse | ServerResponse,
  { forceRefresh = false }: GetTokenOptions = {}
) => {
  const sdk = getAuth0SDK()
  if (!sdk) {
    throw new Error('Auth0 SDK is not available. Are the env vars set?')
  }
  const { accessToken } = await sdk.getAccessToken(req, res, {
    refresh: forceRefresh,
  })
  if (!accessToken) {
    throw new Error('Missing access token')
  }
  return accessToken
}

/**
 * Retrieves the mfaAccessToken from the cookie session. Returns the
 * MFA-scoped access token and expiration timestamp.
 * @param req - The incoming request object
 * @param res - The outgoing response object
 * @returns {Promise} Promise that resolves with an object containing the MFA-scoped accessToken and accessTokenExpiresAt
 */
export const getMfaAccessTokenFromSession = async (
  req: NextApiRequest | IncomingMessage,
  res: NextApiResponse | ServerResponse
) => {
  const mfaAccessToken = (await getSession(req, res))?.mfaAccessToken
  const { accessToken, accessTokenExpiresAt } = mfaAccessToken ?? {}
  if (!accessToken) {
    throw new Error('Missing mfa access token')
  }
  if (!accessTokenExpiresAt) {
    throw new Error('Missing mfa access token expiration')
  }
  if (accessTokenExpiresAt < new Date().getTime() / 1000) {
    throw new Error('Mfa access token expired')
  }
  return {
    accessToken,
    expiresAt: accessTokenExpiresAt,
  }
}

export const forbidCaching = (res: NextApiResponse) => {
  res.setHeader('Cache-Control', CACHE_CONTROL_PREVENT_CACHING)
}
