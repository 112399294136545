import {
  type GetRequestLocation,
  makeFetchGraphQL,
} from '@/utils/dataFetching/reactQuery/graphql'
import { apiGatewayURL } from '@/utils/dataFetching/url'

const getLegacyRequestLocation: GetRequestLocation = () => ({
  baseURL: apiGatewayURL({ newGatewayEnabled: true }),
  url: '/graphql',
})

export const fetchGraphql = makeFetchGraphQL({
  getRequestLocation: getLegacyRequestLocation,
})
