import { type LogMeta } from '@/utils/logger/types'
import { type Event } from '@bugsnag/js'
import isEmpty from 'lodash/isEmpty'
const responseStatusesToGroup = [401, 502, 503, 504]

export function modifyBugsnagEvent(event: Event, meta: LogMeta | undefined) {
  if (meta && !isEmpty(meta)) {
    event.addMetadata('metadata', meta)

    // group all 401s, 502s, 503s and 504s together
    const foundGroupedResponseStatus = responseStatusesToGroup.find(
      (status) => status === meta.responseStatus
    )
    if (foundGroupedResponseStatus) {
      event.groupingHash = `REQUEST_${foundGroupedResponseStatus}`
    }

    if (meta.fetcherName) {
      // if groupingHash is already set, do not overwrite it. We set groupingHash in bugsnag.ts top level handler, FYI
      if (!event.groupingHash) {
        event.groupingHash = meta.fetcherName
      }
      event.context = meta.fetcherName
    }
  }
}
