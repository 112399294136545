import { type CreditCard } from '@/services/CreditCards/types'

export enum PaymentOption {
  credit_card = 'credit_card',
  payment_wallet = 'payment_wallet',
}

type BasePaymentMethod = {
  paymentMethodId: string
  external_id?: string
  verification_required?: boolean
}

export type PaymentMethod =
  | (BasePaymentMethod & {
      option: PaymentOption.payment_wallet
      paymentWalletType: PaymentWalletType
    })
  | (CreditCard & BasePaymentMethod & { option: PaymentOption.credit_card })

export enum PaymentWalletType {
  GooglePay = 'Google Pay',
  ApplePay = 'Apple Pay',
}

export enum CardBrand {
  DinersClub = 'Diners Club',
  Mastercard = 'Mastercard',
  UnionPay = 'UnionPay',
  Visa = 'Visa',
  AmericanExpress = 'American Express',
  Discover = 'Discover',
  JCB = 'JCB',
  EBT = 'EBT',
  TargetMastercard = 'Target Mastercard',
}
