import styled, { css } from 'styled-components'
import { shade } from 'polished'
import { BaseButton } from '@/elements/Buttons/ButtonBase'
import { LoadingIndicatorDot } from '@/elements/LoadingIndicator'
import { staticColors, type StaticColor } from '@/theme/staticColors'

/**
 * @deprecated Use { Button } from '@shipt/design-system-buttons' instead
 */
export const ColorButton = styled(BaseButton)<{
  color: Exclude<StaticColor, 'social'>
}>`
  color: ${staticColors.white};
  background-color: ${({ theme, color }) => theme[color]};
  border: none;

  &:hover,
  &:focus {
    color: ${staticColors.white};
    background-color: ${({ theme, color }) => shade(0.2, theme[color])};
  }

  &:active {
    color: ${staticColors.white};
    background-color: ${({ theme, color }) => shade(0.4, theme[color])};
  }

  ${({ theme, isLoading, color, disabled }) =>
    isLoading &&
    disabled &&
    css`
      &,
      &:hover,
      &:focus,
      &:active {
        color: ${staticColors.white};
        background-color: ${shade(0.4, theme[color])};
      }

      ${LoadingIndicatorDot} {
        background-color: ${theme.primaryContrast};
      }
    `}

  ${({ theme, disabled, isLoading }) =>
    !isLoading &&
    disabled &&
    css`
      &,
      &:hover,
      &:focus,
      &:active {
        color: ${theme.gray600};
        background-color: ${theme.gray300};
      }
    `}
`

/**
 * @deprecated
 * use TransparentButtonV2 instead because it does not introduce
 * new custom styles and helps us deprecate old typography faster
 */
export const TransparentButton = styled(BaseButton)<{ padding?: number }>`
  ${({ padding }) => padding && `padding: ${padding}px`};
  border: none;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
  }
`
