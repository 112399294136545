import axios, { type AxiosRequestConfig, AxiosError } from 'axios'
import { type GetServerSidePropsContext } from 'next'
import safeStringify from 'safe-stable-stringify'
import {
  type ErrorMessage,
  type ErrorResponseBody,
} from '@/utils/dataFetching/types'
import { isErrorMessage } from '@/utils/guards/error'
import { isObject } from '@/utils/isObject'
import { ErrorWithCause, TrackError } from '@/utils/dataFetching/error'
import { type ApiClient } from '@/utils/dataFetching'
import { logError, logInfo } from '@/utils/logger'
import { ClientError } from '@/utils/dataFetching/reactQuery/graphqlTypes'
import { type LogMeta } from '@/utils/logger/types'
import { isRunningTests } from '@/utils/environment'

export const DEFAULT_ERROR_MESSAGE =
  'An unexpected error has occurred. Please try again.'

export const getErrorMessage = (
  error: unknown,
  fallback = DEFAULT_ERROR_MESSAGE
): string => {
  return toError(error)?.message || fallback
}

export const isErrorType = (error: unknown): error is Error =>
  error instanceof Error ||
  (isObject(error) && 'name' in error && 'message' in error)

export const isErrorWithStatus = (error: unknown): error is ErrorWithStatus => {
  return isErrorType(error) && 'status' in error
}

export const isAxiosError = (error: unknown): error is AxiosError => {
  return (
    error instanceof AxiosError ||
    (isErrorType(error) && axios.isAxiosError(error))
  )
}

interface ResponseMetadata {
  baseURL?: string
  responseRayId?: string
  responseStatus?: number
}

export interface ErrorWithStatus extends Error {
  status: number
}

export type ApiErrorResponse<T = ErrorMessage> = AxiosError<
  ErrorResponseBody<T>
> & { response: ErrorResponseBody<T> }

export const isErrorResponseType = <T = ErrorMessage>(
  error: unknown
): error is ApiErrorResponse<T> =>
  isUnknownErrorResponseType(error) && isErrorMessage(error.response.data.error)

export const isUnknownErrorResponseType = (
  error: unknown
): error is ApiErrorResponse<unknown> =>
  isAxiosError(error) &&
  isObject(error.response) &&
  isObject(error.response.data) &&
  'error' in error.response.data

export const isStringErrorResponseType = (
  error: unknown
): error is ApiErrorResponse<string> =>
  isUnknownErrorResponseType(error) &&
  typeof error.response.data.error === 'string'

export class ErrorResponse extends ErrorWithCause implements ErrorMessage {
  name = 'ErrorResponse'
  // NOTE: Remove status once ApiMiddleware is removed from codebase.
  status?: number
  constructor(error: ErrorMessage, options?: ErrorOptions) {
    super(error.message, options)
    this.status = error.status
  }
}

export const toError = (error: unknown, options?: ErrorOptions): Error => {
  if (isErrorResponseType(error)) {
    if (options?.cause) {
      // Axios decided to use `Error` rather than the normal `unknown` type
      // Axios is incorrect and should update
      // @see https://github.com/axios/axios/pull/5850
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      error.cause = options.cause
    }
    return new ErrorResponse(error.response.data.error, { cause: error })
  } else if (isStringErrorResponseType(error)) {
    if (options?.cause) {
      // Axios decided to use `Error` rather than the normal `unknown` type
      // Axios is incorrect and should update
      // @see https://github.com/axios/axios/pull/5850
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      error.cause = options.cause
    }
    return new ErrorResponse(
      { message: error.response.data.error },
      { cause: error }
    )
  } else if (error instanceof Error) {
    if (options?.cause) error.cause = options.cause
    return error
  } else if (isErrorType(error)) {
    // Ensuring a class instance of Error is returned rather than vanilla Object
    const err = new ErrorWithCause(error.message, options)
    err.name = error.name || err.name
    return err
  } else if (isErrorMessage(error)) {
    return new ErrorResponse(error, options)
  }
  return new ErrorWithCause(safeStringify(error), options)
}

export const extractErrorMessages = (error: unknown) => {
  if (
    isObject(error) &&
    isObject(error.response) &&
    isObject(error.response.data) &&
    'errors' in error.response.data &&
    Array.isArray(error.response.data.errors)
  ) {
    return error.response.data.errors
  }
  return []
}

export const extractFirstErrorMessage = (error: unknown): string => {
  const errors = extractErrorMessages(error)
  return getErrorMessage(
    errors[0],
    errors.length === 0 ? DEFAULT_ERROR_MESSAGE : undefined
  )
}

export const getErrorStatus = (error: unknown, fallback = 500): number => {
  if (isAxiosError(error)) return error.response?.status || fallback
  else if (isErrorWithStatus(error)) return error.status || fallback
  return fallback
}

export const extractResponseMetadata = (error: unknown): ResponseMetadata => {
  if (isAxiosError(error)) {
    return {
      baseURL: error.config?.baseURL,
      responseRayId: error.response?.headers?.['fastly-ray-id'],
      responseStatus: error.response?.status,
    }
  } else if (error instanceof ClientError) {
    return {
      baseURL: error.axiosResponse?.config?.baseURL,
      responseRayId: error.axiosResponse?.headers?.['fastly-ray-id'],
      responseStatus: error.axiosResponse?.status,
    }
  }
  return {}
}

export function getErrorReferenceId(error: unknown): string | null {
  return extractResponseMetadata(error).responseRayId?.substring(0, 8) || null
}

// In some instances back-end is providing us empty messages in the
// error response so we need to be able to filter by the Axios error message
function getMessageToLog(error: unknown): string {
  return (
    getErrorMessage(error, '') || (isAxiosError(error) ? error.message : '')
  )
}
export const logDataFetchingErrors = (
  apiClient: ApiClient,
  config: AxiosRequestConfig,
  error: unknown,
  ssrContext?: GetServerSidePropsContext,
  meta?: Record<string, unknown>
) => {
  const ignoredMessages = apiClient.options?.ignoredMessages || []
  for (const message of ignoredMessages) {
    const errorMessage = getMessageToLog(error)

    const shouldIgnoreMessage =
      typeof message === 'string'
        ? errorMessage === message
        : message.test(errorMessage)

    if (shouldIgnoreMessage) {
      const fetcherName = String(meta?.fetcherName || '')
      const groupingHash = `${
        fetcherName ? fetcherName + '-' : ''
      }${message.toString()}`
      logInfo(errorMessage, {}, groupingHash)
      return
    }
  }

  const errTrack = new TrackError()
  const { url, method, params, data, headers } = config ?? {}
  const { store_location_id = 0 } = { ...params, ...data }
  const page_url = ssrContext?.req?.url
  // @ts-expect-error -- accessing custom property off of req
  const isKnownCrawler = ssrContext?.req.isKnownCrawler
  const { baseURL, responseRayId, responseStatus }: ResponseMetadata =
    extractResponseMetadata(error)

  const errorOptions: ErrorOptions = { cause: errTrack }
  logError(
    error,
    {
      ...meta,
      baseURL,
      responseRayId,
      responseStatus,
      url,
      method,
      anonymous_id: params?.anonymous_id,
      is_known_crawler: isKnownCrawler,
      ...(ssrContext && {
        user_agent: ssrContext.req.headers['user-agent'],
        fastly_ray_id: ssrContext.req.headers['fastly-ray-id'],
      }),
      ...(Boolean(params?.user_id && !headers?.Authorization) && {
        user_id_without_auth: true,
      }),
      ...(Boolean(page_url) && { page_url }),
      ...(Boolean(store_location_id) && { store_location_id }),
      ...apiClient.config?.onErrorLog,
      ...(apiClient.fetcherName && {
        fetcherName: apiClient.fetcherName,
      }),
    },
    errorOptions
  )
  if (
    isRunningTests &&
    !axios.isAxiosError(error) &&
    !(error instanceof ClientError)
  ) {
    // log error for tests if it's not an axios error or graphql client error
    // eslint-disable-next-line no-console
    console.error(error, meta, errorOptions)
  }
}

export const buildErrorMetadata = (
  rootObj: LogMeta,
  payloadObj: AxiosRequestConfig['data'],
  allowObjKeys?: Record<string, boolean>
) => {
  for (const key in payloadObj) {
    const dataValue = payloadObj[key]
    if (allowObjKeys?.[key]) {
      rootObj[key] = dataValue
    } else if (Array.isArray(dataValue)) {
      rootObj[key] = `array with length ${dataValue.length}`
    } else if (typeof dataValue === 'object') {
      rootObj[key] = 'object'
    } else {
      rootObj[key] = dataValue
    }
  }

  return rootObj
}
