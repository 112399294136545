import { type ModalType } from '@/constants/modalTypes'
import { dialogAtom, initialDialogValue } from '@/state/Dialog/atom'
import { type DialogSessionItem, type DialogData } from '@/state/Dialog/types'
import { useSetAtom } from 'jotai'
import { useCallback } from 'react'
// eslint-disable-next-line no-restricted-imports -- this needs to be updated to use useAtom or useAtomValue
import { getJotaiStore } from '@/state'

export const useHandleDialog = () => {
  const setDialog = useSetAtom(dialogAtom)

  const openDialog = useCallback(
    <T extends ModalType>(dialogType: T, ...dialogData: DialogData<T>) => {
      setDialog((currentDialog) => ({
        ...currentDialog,
        type: dialogType,
        data: dialogData[0],
      }))
    },
    [setDialog]
  )

  const closeDialog = useCallback(() => {
    setDialog(initialDialogValue)
  }, [setDialog])

  const setDialogSessionItem = useCallback(
    (key: string, data: DialogSessionItem) => {
      setDialog((currentDialog) => ({
        ...currentDialog,
        dialogSessionStorage: {
          ...currentDialog.dialogSessionStorage,
          [key]: data,
        },
      }))
    },
    [setDialog]
  )

  const getDialogSessionItem = (key: string) => {
    const jotaiStore = getJotaiStore()
    const dialog = jotaiStore.get(dialogAtom)
    return dialog.dialogSessionStorage?.[key]
  }

  const clearDialogSessionItem = useCallback(
    (key: string) =>
      setDialog((currentDialog) => ({
        ...currentDialog,
        dialogSessionStorage: {
          ...currentDialog.dialogSessionStorage,
          [key]: undefined,
        },
      })),
    [setDialog]
  )

  return {
    openDialog,
    closeDialog,
    setDialogSessionItem,
    getDialogSessionItem,
    clearDialogSessionItem,
  } as const
}
