export enum API_URL {
  // https://staging-app.shipt.com
  MEMBER_API = 'member-api',
  // https://staging-api.shipt.com
  API = 'api',
  // https://staging-member-api.shipt.com
  NEW_API_GATEWAY = 'new-api-gateway',
  // next api
  SELF_API = 'self',
}
