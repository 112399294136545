import { useMemo } from 'react'
import { isOnServer } from '@shared/constants/util'
import { createStore } from 'jotai'
import { isRunningTests } from '@/utils/environment'
import {
  type JotaiStore,
  type AnyWritableAtom,
  type AtomState,
} from '@/state/types'

export * from './types'

export class JotaiInitialValues {
  private map: Map<AnyWritableAtom, unknown>
  constructor() {
    this.map = new Map<AnyWritableAtom, unknown>()
  }

  setAtom<T extends AnyWritableAtom>({ atom, state }: AtomState<T>) {
    this.map.set(atom, state)
    return this
  }

  atomMap() {
    return this.map
  }
}

let _store: JotaiStore | undefined

export const configureJotaiStore = (): JotaiStore => {
  if (isRunningTests) {
    _store = undefined
  }
  return getJotaiStore()
}

export const getJotaiStore = () => {
  const jotaiStore = _store ?? createStore()
  // For SSG and SSR always create a new store
  if (isOnServer()) return jotaiStore
  if (!_store) _store = jotaiStore
  return jotaiStore
}

export function useCreateJotaiStore(): JotaiStore {
  return useMemo(() => configureJotaiStore(), [])
}
