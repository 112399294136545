import { type CouponCodeQueryKey } from '@/services/SignupPromotions/queries'
import { type CouponCode } from '@/services/SignupPromotions/types'
import { apiGet } from '@/utils/dataFetching'
import { type QueryFunctionContext } from '@tanstack/react-query'

export const fetchCouponCode = async (
  context: QueryFunctionContext<CouponCodeQueryKey>
) => {
  const [, { gift_code }] = context.queryKey
  return apiGet<CouponCode>({
    config: {
      url: '/v1/codes/validate',
      params: {
        code: gift_code,
      },
    },
    fetcherName: 'fetchCouponCode',
  })
}
