import {
  newAuthOverrideCookieName,
  newAuthPublicCookieName,
  hasNewAuthSessionCookieName,
} from '@shared/constants/auth'
import { isOnServer } from '@shared/constants/util'
import { routes } from '@shared/constants/routes'
import {
  hasServerAuthData as hasLegacyServerAuthData,
  hasLegacyClientAuthData,
} from '@/utils/authentication/client/legacy/utils'
import Cookies from 'js-cookie'
import { type ClientSafeSsrContext } from '@/utils/setPageProps'

interface AuthError extends Error {
  name: string
  status: number
}

export class AuthenticationError extends Error implements AuthError {
  name = 'AuthError'
  status = 401
  constructor(message?: string) {
    super(
      message ?? 'This request is unauthorized. Please log in to your account.'
    )
  }
}
/**
 * DO NOT import and use this function directly; use shouldUseNewAuth()
 * @param ssrContext
 * @returns
 */
export const _checkNewAuthCookie = (ssrContext?: ClientSafeSsrContext) => {
  if (isOnServer()) {
    if (!ssrContext) {
      return false
    }
    const { cookies } = ssrContext.req
    return (
      cookies[newAuthOverrideCookieName] === 'true' ||
      cookies[newAuthPublicCookieName] === 'true'
    )
  }
  return (
    Cookies.get(newAuthOverrideCookieName) === 'true' ||
    Cookies.get(newAuthPublicCookieName) === 'true'
  )
}

export const shouldUseNewAuth = (ssrContext?: ClientSafeSsrContext) => {
  if (isOnServer()) {
    if (!ssrContext) {
      // On server w/o ssrContext is SSG, which are generated at build time
      // No reliable way to toggle this on/off at runtime, so set to false
      return false
    }

    // To keep this function synchronous for client-side, simply checking
    // for the existence of these cookies instead of checking the session
    const hasNewAuthSession = Boolean(
      ssrContext.req.cookies['appSession'] ||
        ssrContext.req.cookies['appSession.0']
    )

    if (hasNewAuthSession) {
      // If the user has an auth0 session, we should use new auth
      return true
    } else if (hasLegacyServerAuthData(ssrContext)) {
      // If the user has a legacy auth session, we should not use new auth
      return false
    }
    // No session; we should follow the rollout cookie(s) to decide
    return _checkNewAuthCookie(ssrContext)
  } else {
    if (Cookies.get(hasNewAuthSessionCookieName) === 'true') {
      // If the user has an auth0 session client side, we should use new auth
      return true
    } else if (hasLegacyClientAuthData()) {
      // If the user has a legacy auth session client side, we should not use new auth
      return false
    } else {
      // NO session; we should follow the rollout cookie(s) to decide
      return _checkNewAuthCookie()
    }
  }
}

export const toJSON = async (response: Response) => {
  if (
    response.status === 202 ||
    response.status === 204 ||
    response.status === 205
  ) {
    return response
  }
  if (response.json) {
    return response.json()
  }
}

export const isRequestUnauthorized = (
  status: number,
  url?: string
): boolean => {
  if (status !== 401) {
    return false
  }
  const isLogin =
    typeof window !== 'undefined' &&
    window?.location.pathname === routes.LOGIN.url
  const isAuthEndpoint = url?.includes('auth/v3')
  return !isLogin && !isAuthEndpoint
}

export const handleErrorResponse = async (response: Response) => {
  return response.text().then((text) => {
    try {
      // We are in a transitional phase to normalize our API errors.
      // Here we prioritize the new `error` object (normalized) and resort to
      // the old, inconsistent `errors` object if it is not yet available.
      //
      // Once all API endpoints contain an `error` property, we can remove this
      // check and always use the normalized `error`.
      // Some legacy endpoints only return a single legacy `errors` key.
      // E.g a PATCH request to /api/v1/customers/<id>.json
      const json = JSON.parse(text)
      let error = json.error || { ...json, ...json.errors }
      if (typeof error === 'string') {
        error = { message: error }
      }
      return Promise.reject({ ...error, status: response.status })
    } catch (e) {
      return Promise.reject(text)
    }
  })
}

export const validateResponse = async (response: Response) => {
  if (isRequestUnauthorized(response.status, response.url)) {
    throw new AuthenticationError()
  }
  if (response.ok) {
    return response
  }
  return handleErrorResponse(response)
}

/**
 *
 * @param {Function} func The function to wrap. Should return a Promise.
 * @returns {Function} A debounced function. Initial invocation returns a pending promise unless isOnServer is true then invocation(s) will not be debounced. Subsequent invocations coalesce and receive the pending function without executing the underlying function. Once the Promise settles, further invocations start the process again.
 */

export function safeAsyncDebounce<
  Arg,
  F extends (...args: Arg[]) => Promise<T>,
  T
>(func: F) {
  let locked: boolean, value: Promise<T> | undefined

  function lock(val: Promise<T>) {
    locked = true
    value = Promise.resolve(val).finally(unlock)
    return value
  }

  function unlock() {
    locked = false
    value = undefined
  }

  // This is wrapped
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <F>function mutexedFunc(...args: Arg[]) {
    // DO NOT DEBOUNCE ON THE SERVER!
    if (isOnServer()) return Promise.resolve(func(...args))
    if (locked) {
      return value!
    } else {
      return lock(func(...args))
    }
  }
}
