import { AUTH0_PRELAUNCH_VIEWED } from '@/components/Modal/AuthNoticeModals/constants'
import { ModalType } from '@/constants/modalTypes'
import { useFeatureFlags } from '@/services/FeatureFlags/useFeatureFlags'
import { useQueryTwoFactorAuthPreference } from '@/services/TwoFactorAuth/queries'
import { useUser } from '@/services/User/hooks'
import { useHandleDialog } from '@/state/Dialog/useHandleDialog'
import { hasLegacyClientAuthData } from '@/utils/authentication/client/legacy/utils'
import { getCookie } from '@/utils/cookies'
import { type PageRouteData } from '@/utils/setPageProps'
import {
  newAuthOverrideCookieName,
  newAuthPublicCookieName,
} from '@shared/constants/auth'
import { RouteName } from '@shared/constants/routes'
import { useEffect, useMemo } from 'react'

export const useAuth0MigrationModal = (routeData: PageRouteData) => {
  const { openDialog } = useHandleDialog()
  const { data, isLoading: isLoadingTwoFactorPreferences } =
    useQueryTwoFactorAuthPreference()
  const user = useUser()
  const isAuthenticatedPage = routeData?.authentication
  const isLegacyWelcomePage = routeData?.routeName === RouteName.LEGACY_WELCOME
  const {
    flags: { auth0_migration_prelaunch },
  } = useFeatureFlags()
  const newAuthRolloutEnabled =
    getCookie(newAuthOverrideCookieName) || getCookie(newAuthPublicCookieName)

  const isSocialSessionType = data?.session_type === 'sso'
  const prelaunchModalSeen = useMemo(
    () => getCookie(AUTH0_PRELAUNCH_VIEWED),
    []
  )

  const shouldOpenLaunchModal = useMemo(() => {
    return newAuthRolloutEnabled && user?.auth0_eligible
  }, [newAuthRolloutEnabled, user?.auth0_eligible])

  const shouldOpenPrelaunchModal = useMemo(() => {
    return auth0_migration_prelaunch && !prelaunchModalSeen
  }, [auth0_migration_prelaunch, prelaunchModalSeen])

  useEffect(() => {
    if (
      !hasLegacyClientAuthData() ||
      !isAuthenticatedPage ||
      isLegacyWelcomePage ||
      isLoadingTwoFactorPreferences
    ) {
      return
    }

    if (shouldOpenLaunchModal) {
      return openDialog(ModalType.AUTH_NOTICE_MODALS, {
        type: isSocialSessionType
          ? 'auth0_social_launch'
          : 'auth0_email_launch',
      })
    }

    if (shouldOpenPrelaunchModal) {
      return openDialog(ModalType.AUTH_NOTICE_MODALS, {
        type: 'auth0_prelaunch',
      })
    }
  }, [
    isAuthenticatedPage,
    isLegacyWelcomePage,
    isLoadingTwoFactorPreferences,
    isSocialSessionType,
    openDialog,
    shouldOpenLaunchModal,
    shouldOpenPrelaunchModal,
  ])
}
