import { useCallback, useState } from 'react'
import {
  type QueryClient,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query'
import { useUserAddressParams, useUserId } from '@/services/User/hooks'
import {
  fetchShoppingStoreById,
  fetchShoppingStores,
} from '@/services/ShoppingStore/fetchers'
import {
  type ShoppingStoreParams,
  type FetchShoppingStoresParams,
} from '@/services/ShoppingStore/types'
import { useStoreParams } from '@/utils/dataFetching/storeParams'
import { useSession } from '@/utils/authentication/hooks'

const getShoppingStoreByIdQueryKey = (storeId: number) =>
  [ShoppingStoreById, { storeId }] as const

const ShoppingStoreById = 'Shopping store by id'
export type ShoppingStoreByIdQueryKey = ReturnType<
  typeof getShoppingStoreByIdQueryKey
>

export const useQueryShoppingStoreById = (storeId: number, enabled = true) => {
  const storeParams = useStoreParams()

  return useQuery({
    queryKey: getShoppingStoreByIdQueryKey(storeId),
    queryFn: fetchShoppingStoreById,
    enabled: Boolean(storeId && storeParams.user_id && enabled),
  })
}

export const getShoppingStoresQueryKey = (params: ShoppingStoreParams) =>
  [StoresAndPickupsQuery, params] as const

export const StoresAndPickupsQuery = 'Stores and Pickups'
export type ShoppingStoresKey = ReturnType<typeof getShoppingStoresQueryKey>
type ShoppingStoresProps = {
  zip?: string
  notifyOnChangeProps?: UseQueryOptions['notifyOnChangeProps']
  isDisabled?: boolean
}

export const useQueryShoppingStores = ({
  zip,
  isDisabled,
  notifyOnChangeProps,
}: ShoppingStoresProps = {}) => {
  const userId = useUserId()
  const params = useUserAddressParams({ zip })
  const { isSessionLoading, isSessionValid } = useSession()

  return useQuery({
    notifyOnChangeProps,
    queryKey: getShoppingStoresQueryKey(params),
    queryFn: fetchShoppingStores,
    enabled:
      !isDisabled &&
      !isSessionLoading &&
      Boolean(
        isSessionValid
          ? userId && params && 'address_id' in params && params.address_id
          : params && 'address_zip_code' in params && params.address_zip_code
      ),
  })
}

useQueryShoppingStores.fetcher = fetchShoppingStores
useQueryShoppingStores.getKey = getShoppingStoresQueryKey

export const useQueryShoppingStoresByAddressId = (addressId: number) => {
  const userId = useUserId()
  return useQuery({
    queryKey: getShoppingStoresQueryKey({ address_id: addressId }),
    queryFn: fetchShoppingStores,
    enabled: Boolean(userId && addressId),
  })
}

export const fetchShoppingStoresQuery = (
  queryClient: QueryClient,
  params: ShoppingStoreParams
) =>
  queryClient?.fetchQuery({
    queryKey: useQueryShoppingStores.getKey(params),
    queryFn: useQueryShoppingStores.fetcher,
  })

export const useFetchShoppingStores = () => {
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(false)
  const fetchStores = useCallback(
    async (query: FetchShoppingStoresParams) => {
      const address = 'address' in query && query.address
      const addressId = 'address_id' in query && query.address_id
      setIsLoading(true)
      try {
        const params = {
          ...(addressId && { address_id: addressId }),
          ...(address && {
            ...('street1' in address && {
              address_line_1: address.street1,
              address_city: address.city,
              address_state: address.state,
            }),
            address_zip_code: address.zip_code,
          }),
        }
        return await fetchShoppingStoresQuery(queryClient, params)
      } finally {
        setIsLoading(false)
      }
    },
    [queryClient]
  )
  return { fetchStores, isLoading }
}

const HomepageStores = 'Homepage stores'

export const getHomepageStoresQueryKey = (zip_code: string) =>
  [HomepageStores, { address_zip_code: zip_code }] as const
