import { apiGet } from '@/utils/dataFetching'
import { type QueryFunctionContext } from '@tanstack/react-query'
import { type AddressesQueryKey } from '@/services/Addresses/queries'
import {
  type Address,
  type AddressesResponse,
} from '@/services/Addresses/types'
import { API_URL } from '@/utils/dataFetching/apiUrl'

export const fetchAddresses = async (
  context: QueryFunctionContext<AddressesQueryKey>
): Promise<Address[]> => {
  const { signal } = context
  const addressResponse = await apiGet<AddressesResponse>({
    config: {
      url: 'api/v1/customer_addresses.json',
      signal,
    },
    options: {
      apiUrl: API_URL.MEMBER_API,
    },
    context,
    fetcherName: 'fetchAddresses',
  })
  return addressResponse.customer_addresses
}
