import dynamic from 'next/dynamic'

const DialogContainer = dynamic(
  () => import('@/containers/DialogContainer').then((m) => m.DialogContainer),
  { ssr: false }
)

export const DynamicDialogContainer = () => {
  return <DialogContainer />
}
