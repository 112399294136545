import { atomWithReset } from 'jotai/utils'
import { type DialogContext } from '@/state/Dialog/types'

export const initialDialogValue: DialogContext = {
  type: '',
  data: {},
  dialogSessionStorage: {},
}

export const dialogAtom = atomWithReset<DialogContext>(initialDialogValue)
dialogAtom.debugLabel = 'dialogAtom'
