import {
  type LogoutOptions,
  type AuthClient,
  type GetAccessTokenOptions,
} from '@/utils/authentication/client'
import {
  _clearCachedAuthData,
  _retrieveAccessToken,
  _retrieveAuthData,
} from '@/utils/authentication/client/auth0/service'
import { getIsTokenExpired } from '@/utils/authentication/client/auth0/utils'
import { resetUser } from '@/utils/authentication'
import { isOnServer } from '@shared/constants/util'
import { getAccessTokenFromSession } from '@/serverUtils/auth/helpers'
import { safeAsyncDebounce } from '@/utils/authentication/utils'
import { logError } from '@/utils/logger'

const _clientSideGetAccessToken = safeAsyncDebounce(
  async function _getAccessToken({ forceRefresh }: GetAccessTokenOptions = {}) {
    return _retrieveAccessToken({ forceRefresh }).catch((err) => {
      // If the refresh auth call failed we CANNOT continue. We must clear any auth data and logout.
      logError(new Error('Error fetching or refreshing auth token'), {
        cause: err,
      })
      _logout()
      throw err
    })
  }
)

const _getAccessToken = async ({
  ssrContext,
  forceRefresh = false,
}: GetAccessTokenOptions = {}) => {
  if (isOnServer()) {
    if (!ssrContext) {
      throw new Error(
        'getAccessToken requires ssrContext when called on the server'
      )
    }
    return getAccessTokenFromSession(ssrContext.req, ssrContext.res, {
      forceRefresh,
    })
  }
  return _clientSideGetAccessToken({ forceRefresh })
}

const _getIsTokenExpired = async () => {
  try {
    const { expiresAt } = await _retrieveAuthData()
    return getIsTokenExpired(expiresAt)
  } catch {
    return false
  }
}

const _logout = async ({ shouldRedirect = true }: LogoutOptions = {}) => {
  _clearCachedAuthData()
  resetUser({ shouldRedirect })
}

export const _createClient = (): AuthClient => {
  return {
    getAccessToken: _getAccessToken,
    getIsTokenExpired: _getIsTokenExpired,
    logout: _logout,
    persistence: {
      getAuthData: () => Promise.resolve({}),
      setAuthData: () => Promise.resolve(),
      deleteAuthData: () => Promise.resolve(),
    },
  }
}
