import { forwardRef, type ComponentProps, type Ref } from 'react'
import styled, { type StyledComponentPropsWithRef } from 'styled-components'
import { Button, TextButton } from '@shipt/design-system-buttons'
import { NextLink, type NextLinkProps } from '@/elements/Link'
import { Link, type TypographyProps } from '@shipt/design-system-typography'
import { TransparentButtonV2 } from '@/elements/Buttons'
import { getLinkNewTabAttributes } from '@/elements/Link/utils'

const forwardedAsProps: Record<string, unknown> = { forwardedAs: 'a' }

type LinkProps = Omit<ComponentProps<'a'>, 'href'> & {
  isLink?: never
  forwardedAs?: never
  href: NextLinkProps['href']
  replace?: NextLinkProps['replace']
  shallow?: NextLinkProps['shallow']
  opensInNewTab?: boolean
}

type ButtonProps = StyledComponentPropsWithRef<typeof Button> & LinkProps

export const ButtonLink = ({
  href,
  replace,
  shallow,
  opensInNewTab = false,
  ...restProps
}: ButtonProps) => {
  return (
    <NextLink href={href} replace={replace} legacyBehavior shallow={shallow}>
      <Button
        {...restProps}
        {...forwardedAsProps}
        {...getLinkNewTabAttributes(opensInNewTab)}
        isLink
      />
    </NextLink>
  )
}

type TextButtonProps = StyledComponentPropsWithRef<typeof TextButton> &
  LinkProps

export const TextButtonLink = forwardRef(
  (
    { href, shallow, opensInNewTab = false, ...restProps }: TextButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    return (
      <NextLink href={href} legacyBehavior shallow={shallow}>
        <TextButton
          ref={ref}
          {...restProps}
          {...forwardedAsProps}
          {...getLinkNewTabAttributes(opensInNewTab)}
          isLink
        />
      </NextLink>
    )
  }
)

export const ExternalButton = forwardRef(
  (
    { children, ...props }: JSX.IntrinsicElements['button'] & TypographyProps,
    ref
  ) => (
    <ExternalLink {...props} as={TransparentButtonV2} ref={ref}>
      {children}
    </ExternalLink>
  )
)

const ExternalLink = styled(Link)`
  font-weight: normal;
  white-space: nowrap;
  align-self: start;
`
