import { type SignupPlansResponse } from '@/services/MembershipPlan/types'
import { apiGet } from '@/utils/dataFetching'
import { type QueryFunctionContext } from '@tanstack/react-query'
import {
  type DeliveryPlanQueryKey,
  type SignupPlansKey,
} from '@/services/DeliveryPlans/queries'
import { type FetchPackagesResponse } from '@/services/DeliveryPlans/types'

export const fetchSignupPlans = (
  context: QueryFunctionContext<SignupPlansKey>
) => {
  const [, params] = context.queryKey
  const ssrContext = context.meta?.ssrContext

  return apiGet<SignupPlansResponse>({
    config: {
      url: 'signup_promotions/find_plans',
      params: {
        ...params,
        referring_url:
          params.referring_url ||
          (ssrContext ? ssrContext.req.headers.referer : document.referrer) ||
          '',
      },
    },
    context,
    fetcherName: 'fetchSignupPlans',
  })
}

export const fetchDeliveryPlans = (
  context: QueryFunctionContext<DeliveryPlanQueryKey>
) => {
  const [, params] = context.queryKey

  return apiGet<FetchPackagesResponse>({
    config: {
      url: 'prepaid-shops/v2/packages',
      params: params.storeParams,
    },
    context,
    fetcherName: 'fetchDeliveryPlans',
  })
}
